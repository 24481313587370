<template>
  <div class="page-wrapper">
    <div class="wrapper" v-if="isLoaded">
      <div class="" v-if="type === 'KYC'">
        <KYC/>
      </div>
      <div class="" v-if="type === 'FAQ'">
        <FAQ/>
      </div>
    </div>
  </div>
</template>

<script>
import LocalStorageService from "@/services/LocalStorageService";
import KYC from "@/components/mobile-application/KYC.vue"
import FAQ from "@/components/mobile-application/FAQ.vue"

export default {
  name: "MobileApplication",
  components: {
    KYC,
    FAQ
  },
  data() {
    return {
      isLoaded: false,
    }
  },
  computed: {
    type() {
      return this.$route.query.type;
    }
  },
  methods: {
    async getTokens() {
      const {accessToken, refreshToken} = await this.$api.auth.getNewTokens(this.$route.query.token)
      LocalStorageService.setToken(accessToken, refreshToken)
    }
  },
  mounted() {
    this.isLoaded = false
    this.getTokens()
    this.isLoaded = true
  }
}
</script>

<style scoped>
.page-wrapper {
  display: flex;
  justify-content: center;
}
</style>