<template>
  <div id="my-header">
    <header class="header new-header main-ui-fixed">
      <div class="container-full">
        <div class="header-wrapper">
          <div class="header-logo-wrap">
            <router-link :to="{ path: '/home' }">
              <img
                src="@/assets/img/main-logo.svg"
                alt="Header logo"
                class="header-logo"
                id="header_desk_home"
                loading="lazy"
              />
            </router-link>
          </div>
          <nav class="header-nav-wrap">
            <ul class="header-nav">
              <li class="header-nav__item">
                <router-link
                  :to="{
                    path: '/artists',
                    query: { page: '1', artist: 'djk-x' },
                  }"
                  class="header-nav__item-link"
                  id="header_desk_artists"
                  :class="{ 'actual-tab': changeHeaderTab === 'artists' }"
                  >Artists</router-link
                >
                <!--                <router-link :to="{path: '/artists'}"-->
                <!--                             class="header-nav__item-link" id="header_desk_artists">Artists-->
                <!--                </router-link>-->
              </li>
              <li class="header-nav__item">
                <router-link
                  :to="{ path: '/auction' }"
                  class="header-nav__item-link"
                  id="header_desk_pm"
                  :class="{ 'actual-tab': changeHeaderTab === 'auction' }"
                  >Auctions</router-link
                >
              </li>
              <li class="header-nav__item">
                <router-link
                  :to="{ path: '/market' }"
                  class="header-nav__item-link"
                  id="header_desk_trading"
                  :class="{ 'actual-tab': changeHeaderTab === 'market' }"
                  >DjookyX Marketplace</router-link
                >
              </li>
              <li class="header-nav__item">
                <router-link
                  :to="{ path: '/how-it-works' }"
                  class="header-nav__item-link"
                  id="header_desk_about"
                  :class="{ 'actual-tab': changeHeaderTab === 'how-it-works' }"
                  >How It Works</router-link
                >
              </li>
              <li class="header-nav__item">
                <router-link
                  :to="{ path: '/news' }"
                  class="header-nav__item-link"
                  id="header_desk_news"
                  :class="{ 'actual-tab': changeHeaderTab === 'news' }"
                  >News and Features</router-link
                >
              </li>

              <li class="header-nav__item">
                <router-link
                  :to="{ path: '/nft-marketplace' }"
                  class="header-nav__item-link"
                  id="header_desk_news"
                  :class="{
                    'actual-tab': changeHeaderTab === 'nft-marketplace',
                  }"
                  >NFT Marketplace</router-link
                >
              </li>

              <!--              <li class="header-nav__item">-->
              <!--                <router-link :to="{path: '/team'}" class="header-nav__item-link" id="header_desk_team">Team-->
              <!--                </router-link>-->
              <!--              </li>-->
              <!--              <li class="header-nav__item" v-if="isLogged">-->
              <!--                <router-link :to="{path: '/profile'}" class="header-nav__item-link" id="header_desk_profile">My-->
              <!--                  Profile-->
              <!--                </router-link>-->
              <!--              </li>-->
            </ul>
          </nav>

          <router-link
            class="profile-amount-over-wrapper"
            :to="{ path: '/profile/wallet' }"
            v-if="money >= 0 && isLogged"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.5 6.75H4.5C3.25736 6.75 2.25 7.75736 2.25 9V18C2.25 19.2426 3.25736 20.25 4.5 20.25H19.5C20.7426 20.25 21.75 19.2426 21.75 18V9C21.75 7.75736 20.7426 6.75 19.5 6.75Z"
                stroke="white"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <path
                d="M19.2825 6.75022V5.34397C19.2824 4.99907 19.2061 4.65844 19.0592 4.34639C18.9123 4.03434 18.6983 3.75856 18.4326 3.53873C18.1668 3.3189 17.8558 3.16044 17.5217 3.07464C17.1877 2.98885 16.8388 2.97784 16.5 3.04241L4.155 5.14944C3.6189 5.2516 3.13526 5.53765 2.78749 5.95824C2.43972 6.37883 2.24963 6.9076 2.25 7.45334V9.75021"
                stroke="white"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <path
                d="M17.25 15C16.9533 15 16.6633 14.912 16.4166 14.7472C16.17 14.5824 15.9777 14.3481 15.8642 14.074C15.7506 13.7999 15.7209 13.4983 15.7788 13.2074C15.8367 12.9164 15.9796 12.6491 16.1893 12.4393C16.3991 12.2296 16.6664 12.0867 16.9574 12.0288C17.2483 11.9709 17.5499 12.0006 17.824 12.1142C18.0981 12.2277 18.3324 12.42 18.4972 12.6666C18.662 12.9133 18.75 13.2033 18.75 13.5C18.75 13.8978 18.592 14.2794 18.3106 14.5607C18.0293 14.842 17.6478 15 17.25 15Z"
                fill="white"
              />
            </svg>

            <div class="profile-amount-wrap">
              <span class="profile-amount-value" v-if="money < 1000"
                >€{{ moneyFormat }}</span
              >
              <span class="profile-amount-value" v-else
                >€{{ newAmount }}k+</span
              >
            </div>
          </router-link>

          <HeaderNotificationsTemplate
            v-if="isLogged"
            :notifications="notifications"
            @changedStatus="changeStatus"
          />

          <div
            class="header-user-info"
            v-if="isLogged"
            v-click-outside="closeHeaderMenu"
          >
            <div
              class="header-user-link"
              :class="
                verified === null ? '' : verified ? 'verified' : 'unverified'
              "
              @click="toggleHeaderMenu"
            >
              <img
                v-if="!userPhotoError && user.userPhoto !== null && user.userPhoto !== ''"
                :src="user.userPhoto"
                class="header-user-photo"
                alt="User photo"
                loading="lazy"
                @error="loadUserPhotoError"
              />

              <svg
                v-else
                class="header-user-photo"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" rx="19" fill="#D7D7D7" />
                <path
                  d="M24.0001 22C27.0772 22 29.5716 19.5056 29.5716 16.4286C29.5716 13.3516 27.0772 10.8572 24.0001 10.8572C20.9231 10.8572 18.4287 13.3516 18.4287 16.4286C18.4287 19.5056 20.9231 22 24.0001 22Z"
                  fill="white"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M34.5943 33.1428C33.8746 30.8986 32.4608 28.9409 30.5568 27.552C28.6528 26.163 26.3568 25.4146 24 25.4146C21.6432 25.4146 19.3473 26.163 17.4433 27.552C15.5392 28.9409 14.1255 30.8986 13.4058 33.1428H34.5943Z"
                  fill="white"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <div
              class="header-user-menu-wrapper"
              :style="{ display: openHeaderMenu ? 'block' : 'none' }"
            >
              <ul class="header-user-menu-list">
                <li class="header-user-menu-item" v-if="!verified">
                  <router-link :to="{ path: '/profile/kyc' }">
                    <span class="header-user-menu-item-text"
                      >Verify account</span
                    >
                  </router-link>
                </li>
                <li
                  class="header-user-menu-item"
                  v-if="user.userType === 'ARTIST'"
                >
                  <router-link :to="{ path: `/artist/${user.userNameId}` }">
                    <span class="header-user-menu-item-text">View Profile</span>
                  </router-link>
                </li>
                <li
                  class="header-user-menu-item"
                  v-if="user.userType === 'COMPANY'"
                >
                  <router-link :to="{ path: `/company/${user.userNameId}` }">
                    <span class="header-user-menu-item-text"
                      >View Company Profile</span
                    >
                  </router-link>
                </li>
                <li class="header-user-menu-item">
                  <router-link :to="{ path: '/profile' }">
                    <span class="header-user-menu-item-text">My account</span>
                  </router-link>
                </li>
<!--                <li-->
<!--                  class="header-user-menu-item"-->
<!--                  v-if="account"-->
<!--                  @click="signOut"-->
<!--                >-->
<!--                  <router-link :to="{ path: '/profile' }">-->
<!--                    <button>-->
<!--                      <span class="header-user-menu-item-text"-->
<!--                        >Disconnect NEAR wallet</span-->
<!--                      >-->
<!--                    </button>-->
<!--                  </router-link>-->
<!--                </li>-->

                <li class="header-user-menu-item">
                  <form @submit.prevent="handleSubmitLogout">
                    <button type="submit">
                      <span class="header-user-menu-item-text"> Log out </span>
                    </button>
                  </form>
                </li>
              </ul>
            </div>
          </div>

          <!--          logout button-->

          <!--          <form v-if="isLogged"-->
          <!--                @submit.prevent="handleSubmitLogout">-->
          <!--            <button type="submit" class="header-logout" id="header-logout-button">-->
          <!--              <svg xmlns="http://www.w3.org/2000/svg" width="24.285" height="24.285">-->
          <!--                viewBox="0 0 24.285 24.285">-->
          <!--                <defs></defs>-->
          <!--                <g transform="translate(-2 -2)">-->
          <!--                  <path class="a"-->
          <!--                        d="M6.857,2A4.857,4.857,0,0,0,2,6.857V10.5H4.428V6.857A2.428,2.428,0,0,1,6.857,4.428H21.428a2.428,2.428,0,0,1,2.428,2.428V21.428a2.428,2.428,0,0,1-2.428,2.428H6.857a2.428,2.428,0,0,1-2.428-2.428V17.785H2v3.643a4.857,4.857,0,0,0,4.857,4.857H21.428a4.857,4.857,0,0,0,4.857-4.857V6.857A4.857,4.857,0,0,0,21.428,2Z"/>-->
          <!--                  <path class="a"-->
          <!--                        d="M3.17,12.08a1.258,1.258,0,0,0,0,2.509h11.21L11.413,17.77a1.32,1.32,0,0,0,0,1.774,1.115,1.115,0,0,0,1.654,0l4.908-5.264a1.315,1.315,0,0,0,0-1.892L13.068,7.125a1.115,1.115,0,0,0-1.654,0,1.32,1.32,0,0,0,0,1.774l2.966,3.181Z"-->
          <!--                        transform="translate(0 0.808)"/>-->
          <!--                </g>-->
          <!--              </svg>-->
          <!--            </button>-->
          <!--          </form>-->

          <!--         end logout button-->

          <button
            class="btn authorization-link"
            id="header_desk_sign-in"
            @click="$router.push({ path: '/login' }).catch(() => {})"
            :disabled="isPageLogin"
            v-if="!isLogged"
          >
            Sign In
          </button>

          <Slide
            :closeOnNavigation="true"
            right
            push
            class="navigator123"
            :width="calcWidth"
          >
            <ul class="header-nav">
              <li class="header-nav__item">
                <router-link
                  :to="{
                    path: '/artists',
                    query: { page: '1', artist: 'djk-x' },
                  }"
                  class="header-nav__item-link"
                  id="header_mobile_artists"
                  >Artists
                </router-link>
              </li>
              <li class="header-nav__item">
                <router-link
                  :to="{ path: '/auction' }"
                  class="header-nav__item-link"
                  id="header_mobile_pm"
                  >Auctions
                </router-link>
              </li>
              <li class="header-nav__item">
                <router-link
                  :to="{ path: '/market' }"
                  class="header-nav__item-link"
                  id="header_mobile_home"
                  >DjookyX Marketplace
                </router-link>
              </li>
              <li class="header-nav__item">
                <router-link
                  :to="{ path: '/how-it-works' }"
                  class="header-nav__item-link"
                  id="header_mobile_about"
                  >How It Works
                </router-link>
              </li>
              <li class="header-nav__item">
                <router-link
                  :to="{ path: '/news' }"
                  class="header-nav__item-link"
                  id="header_mobile_news"
                  >News and Features
                </router-link>
              </li>
              <li class="header-nav__item">
                <router-link
                  :to="{ path: '/nft-marketplace' }"
                  class="header-nav__item-link"
                  id="header_mobile_news"
                  >NFT Marketplace
                </router-link>
              </li>
              <!--              <li class="header-nav__item">-->
              <!--                <router-link :to="{path: '/team'}" class="header-nav__item-link" id="header_desk_team">Team-->
              <!--                </router-link>-->
              <!--              </li>-->
              <li class="header-nav__item" v-if="isLogged">
                <router-link
                  :to="{ path: '/profile' }"
                  class="header-nav__item-link"
                  id="header_mobile_profile"
                  >My Account</router-link
                >
              </li>
            </ul>
          </Slide>

          <!--          <a @click="openMenu" class="hamburger hamburger&#45;&#45;emphatic">-->
          <!--            <span class="hamburger-box">-->
          <!--                <span class="hamburger-inner"></span>-->
          <!--            </span>-->
          <!--          </a>-->
        </div>
      </div>
    </header>
  </div>
</template>

<style lang="css">
@import "../../assets/css/fonts.css";
</style>

<script>
import { mapGetters, mapActions } from "vuex";
// import vArtNearApi from "@v-art-protocol/v-art-protocol-near-sdk";
import { Slide } from "vue-burger-menu";
import HeaderNotificationsTemplate from "./HeaderNotificationsTemplate";
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";

export default {
  name: "Header",

  data() {
    return {
      // account: null,
      newAmount: 0,
      amount: 0,
      windowWidth: window.innerWidth,
      width: "440",
      submitted: false,
      userPhotoError: false,
      headerFirstName: null,
      isPageLogin: Boolean,
      // menuIsOpen: false,
      notifications: null,
      interval: null,
      tabs: JSON.parse(localStorage.getItem("tabs")),
      openHeaderMenu: false,
      changeHeaderTab: "",
    };
  },
  methods: {
    ...mapActions({
      setTabs: "userTabs/setTabs",
      clearMoney: "money/clearMoney",
      setMoney: "money/setMoney",
    }),
    loadUserPhotoError() {
      this.userPhotoError = true;
    },
    // checkCurrentRouteAndRedirect(router, path) {
    //   const {
    //     currentRoute: {path: curPath}
    //   } = router;
    //   if (curPath !== path) router.push({path});
    // },
    handleSubmitLogout() {
      this.submitted = true;
      const { dispatch } = this.$store;
      dispatch("authentication/logout");
      dispatch("money/clearMoney");
    },
    // openMenu() {
    //   this.$emit('openMobileMenu')
    //   this.menuIsOpen = true
    // },
    signOut() {
      console.log("signOut");
      // vArtNearApi.signOut();
    },
    changeStatus() {
      if (this.isLogged) {
        this.$load(async () => {
          this.notifications = (
            await this.$api.notifications.getNotViewed()
          ).data.body.elements;
        });
      }
    },
    toggleHeaderMenu() {
      this.openHeaderMenu = !this.openHeaderMenu;
    },
    closeHeaderMenu() {
      this.openHeaderMenu = false;
    },
    getStrInPath(path) {
      let routeArr = path.split("/");
      this.changeHeaderTab = routeArr[1];
    },
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  computed: {
    ...mapGetters({
      isLogged: "authentication/isLogged",
      user: "user/getUser",
      userTabs: "userTabs/getTabs",
      money: "money/getMoney",
    }),
    calcWidth() {
      let w = this.width + "%";
      return this.windowWidth <= 576
        ? (w = this.windowWidth / 1.5 + "")
        : (w = "440");
    },
    moneyFormat() {
      return this.numberFormattingDecimal(this.money);
    },
    verified() {
      // return this.userTabs.kycTab ? this.userTabs.kycTab.isHideVerified && this.userTabs.kycTab.kycFlow === 'NONE' : null
      return this.userTabs.kycTab
        ? this.userTabs.kycTab.kycFlow === "NONE"
        : null;
    },
  },
  watch: {
    money(newVal) {
      this.newAmount = Math.floor(newVal / 1000);
    },
    "$route.path": {
      deep: true,
      handler() {
        this.getStrInPath(this.$route.path);
      },
    },
  },
  mounted() {
    if (this.isLogged) {
      this.$load(async () => {
        this.notifications = (
          await this.$api.notifications.getNotViewed()
        ).data.body.elements;
      });
      this.setTabs();
      this.newAmount = Math.floor(this.money / 1000);
      this.interval = setInterval(() => {
        if (this.isLogged) {
          this.setMoney();
        } else {
          clearInterval(this.interval);
        }
      }, 5000);
    } else {
      clearInterval(this.interval);
    }
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    this.$route.path === "/login"
      ? (this.isPageLogin = true)
      : (this.isPageLogin = false);
    this.getStrInPath(this.$route.path);
  },
  created() {
    if (!this.isLogged) {
      clearInterval(this.interval);
    }
  },
  updated() {
    // this.account = vArtNearApi.getAccount();
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
  components: {
    HeaderNotificationsTemplate,
    Slide,
  },
};
</script>

<style></style>
