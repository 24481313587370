<template>
  <div id="my-content">
    <div class="profile kyc">
      <div class="profile-content" v-if="loadFlag">
        <h1 class="kyc-title">KYC</h1>

        <div class="kyc-status-wrapper" v-if="userTabs.kycTab.kycFlow === 'SUMSUB_NATURAL'">
          <div class="kyc-body-wrapper">
            <div class="kyc-descr" v-if="nextKYCstep">
              <p class="kyc-descr-text">
                To start an initial sale or to take part in the marketplace you have to go through the
                verification process which is also known as KYC (Know Your Customer) procedure. We are doing this to
                make
                a
                transparent and safe environment for artists and users to prevent fraud and money laundering. It will
                take
                just a couple of minutes.
              </p>
            </div>
            <div id="sumsub-websdk-container"></div>
            <button v-show="nextKYCstep" class="btn kyc-btn" @click="loadKYC">OPEN KYC</button>
          </div>
        </div>

        <div class="kyc-status-wrapper" v-if="userTabs.kycTab.kycFlow === 'MANGOPAY_UPLOAD_NATURAL'">
          <div class="kyc-mangopay-docs-title">
            Please provide additional information for your documents
          </div>
          <div class="kyc-mangopay-docs-images">
            <div class="docs-images-item">
              <svg viewBox="0 0 174 113" fill="none" xmlns="http://www.w3.org/2000/svg" class="docs-images-item__first">
                <rect y="0.980469" width="174" height="111.039" rx="28.6184" fill="white"/>
                <rect x="9.73057" y="10.7106" width="154.539" height="91.5789" rx="22.3224" fill="white" stroke="black"
                      stroke-width="1.14474"/>
                <rect x="16.0254" y="17.0068" width="141.947" height="78.9868" rx="18.3158" fill="#F0F0F0"/>
                <rect x="84.7109" y="37.6123" width="49.2237" height="2.28947" rx="1.14474" fill="black"/>
                <rect x="84.7109" y="49.0596" width="49.2237" height="2.28947" rx="1.14474" fill="black"/>
                <rect x="84.7109" y="60.5068" width="49.2237" height="2.28947" rx="1.14474" fill="black"/>
                <rect x="84.7109" y="71.9546" width="49.2237" height="2.28947" rx="1.14474" fill="black"/>
                <path
                    d="M69.8296 53.0663C69.8296 57.0804 68.1826 62.4549 65.4748 66.8251C62.7322 71.2518 59.1671 74.2439 55.5204 74.2439C51.8736 74.2439 48.3085 71.2518 45.5659 66.8251C42.8581 62.4549 41.2111 57.0804 41.2111 53.0663C41.2111 45.1635 47.6176 38.757 55.5204 38.757C63.4231 38.757 69.8296 45.1635 69.8296 53.0663Z"
                    fill="white" stroke="black" stroke-width="2.28947"/>
              </svg>
              <svg viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="docs-images-item__sign">
                <path d="M2.0108 13.4101L10.3012 20.3916L22.5187 8.17408" stroke="#FF0090" stroke-width="4"
                      stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </div>
            <div class="docs-images-item">
              <svg width="181" height="97" viewBox="0 0 181 97" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="docs-images-item__second">
                <rect x="-26" width="206.351" height="131.684" rx="30.36" fill="white"/>
                <rect x="-14.4599" y="11.5396" width="183.272" height="108.606" rx="22.9012" fill="white" stroke="black"
                      stroke-width="1.35757"/>
                <rect x="-6.99414" y="19.0063" width="168.339" height="93.6723" rx="18.15" fill="#F0F0F0"/>
                <rect x="74.459" y="43.4424" width="58.3755" height="2.71514" rx="1.35757" fill="black"/>
                <rect x="74.459" y="57.0181" width="58.3755" height="2.71514" rx="1.35757" fill="black"/>
                <rect x="74.459" y="70.5938" width="58.3755" height="2.71514" rx="1.35757" fill="black"/>
                <rect x="74.459" y="84.1694" width="58.3755" height="2.71514" rx="1.35757" fill="black"/>
                <path
                    d="M56.8124 61.7696C56.8124 66.5301 54.8592 72.9038 51.6481 78.0865C48.3955 83.3362 44.1676 86.8846 39.8428 86.8846C35.518 86.8846 31.2902 83.3362 28.0376 78.0865C24.8264 72.9038 22.8732 66.5301 22.8732 61.7696C22.8732 52.3975 30.4707 44.8 39.8428 44.8C49.2149 44.8 56.8124 52.3975 56.8124 61.7696Z"
                    fill="white" stroke="black" stroke-width="2.71514"/>
              </svg>
              <svg viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="docs-images-item__sign">
                <path d="M2.61182 18.1513L17.5971 3.16565" stroke="#272727" stroke-width="5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M3.28022 2.55317L16.9313 18.7635" stroke="#272727" stroke-width="5" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>

            </div>
            <div class="docs-images-item">
              <svg viewBox="0 0 95 60" fill="none" xmlns="http://www.w3.org/2000/svg" class="docs-images-item__third">
                <rect width="94.0206" height="60" rx="15.4639" fill="white"/>
                <rect x="5.25654" y="5.25801" width="83.5052" height="49.4845" rx="12.0619" fill="white" stroke="black"
                      stroke-width="0.618557"/>
                <rect x="8.66016" y="8.66016" width="76.701" height="42.6804" rx="9.89691" fill="#F0F0F0"/>
                <rect x="45.7734" y="19.7935" width="26.5979" height="1.23711" rx="0.618557" fill="black"/>
                <rect x="45.7734" y="25.9795" width="26.5979" height="1.23711" rx="0.618557" fill="black"/>
                <rect x="45.7734" y="32.165" width="26.5979" height="1.23711" rx="0.618557" fill="black"/>
                <rect x="45.7734" y="38.3501" width="26.5979" height="1.23711" rx="0.618557" fill="black"/>
                <path
                    d="M37.7309 28.144C37.7309 30.313 36.841 33.2171 35.3778 35.5786C33.8958 37.9705 31.9695 39.5873 29.999 39.5873C28.0284 39.5873 26.1021 37.9705 24.6201 35.5786C23.1569 33.2171 22.267 30.313 22.267 28.144C22.267 23.8737 25.7287 20.412 29.999 20.412C34.2692 20.412 37.7309 23.8737 37.7309 28.144Z"
                    fill="white" stroke="black" stroke-width="1.23711"/>
              </svg>
              <svg viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="docs-images-item__sign">
                <path d="M2.61182 18.1513L17.5971 3.16565" stroke="#272727" stroke-width="5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M3.28022 2.55317L16.9313 18.7635" stroke="#272727" stroke-width="5" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>

            </div>
          </div>
          <div class="kyc-mangopay-docs-upload">
            <div class="docs-upload-wrap">

              <label for="kycUploadFirst" class="docs-upload-item" v-if="!doc1">
                <input type="file"
                       id="kycUploadFirst"
                       class="add-document"
                       accept="image/*"
                       ref="doc1"
                       name="frontSide"
                       @change="setFile"
                       @focus="focus = true"
                >
                <div class="fake-input__upload">
                  <svg width="75" height="67" viewBox="0 0 75 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M60.2609 18.7868C57.7407 6.14194 45.4471 -2.0654 32.8022 0.454814C23.5536 2.29815 16.322 9.52519 14.473 18.7726C5.50735 19.8059 -0.923829 27.9113 0.108849 36.8775C1.05955 45.1292 8.04863 51.3549 16.355 51.3492H25.0026C26.292 51.3492 27.3372 50.304 27.3372 49.0146C27.3372 47.7252 26.292 46.68 25.0026 46.68H16.355C9.90791 46.6418 4.7132 41.3851 4.75078 34.9385C4.78892 28.492 10.0457 23.2967 16.4928 23.3343C17.6826 23.3348 18.6823 22.4394 18.8132 21.257C19.9984 11.0104 29.2658 3.665 39.5123 4.85024C48.1267 5.84706 54.9222 12.642 55.9184 21.257C56.1143 22.4604 57.1572 23.3422 58.3766 23.3343C64.8237 23.3343 70.0497 28.5603 70.0497 35.0074C70.0497 41.454 64.8237 46.68 58.3766 46.68H49.729C48.4396 46.68 47.3944 47.7252 47.3944 49.0146C47.3944 50.304 48.4396 51.3492 49.729 51.3492H58.3772C67.402 51.2929 74.6729 43.9315 74.6171 34.9061C74.5658 26.6873 68.4187 19.7854 60.2609 18.7868Z"
                        fill="#FF0090"/>
                    <path
                        d="M45.6187 35.8436C46.5148 36.7715 47.9927 36.7972 48.92 35.9011C49.8474 35.0056 49.873 33.5278 48.9775 32.6004C48.9587 32.5805 48.9394 32.5617 48.92 32.5429L39.0191 22.6397C38.1088 21.7265 36.6303 21.7248 35.7172 22.6351C35.7161 22.6368 35.7144 22.638 35.7132 22.6397L25.8123 32.5429C24.8849 33.4384 24.8593 34.9162 25.7548 35.8436C26.6508 36.771 28.1287 36.7966 29.056 35.9011C29.0754 35.8823 29.0948 35.863 29.1135 35.8436L35.0318 29.9231V64.3477C35.0318 65.6371 36.077 66.6823 37.3664 66.6823C38.6553 66.6823 39.7005 65.6371 39.7005 64.3477V29.9231L45.6187 35.8436Z"
                        fill="#FF0090"/>
                  </svg>
                  <p class="fake-input__upload-text">Upload the <span>front</span><br/>of your document*</p>
                </div>
              </label>

              <div class="kyc-preview-docs" v-else>
                <label for="kycUploadFirstPreview" class="kyc-preview-docs__reload">
                  <input type="file"
                         id="kycUploadFirstPreview"
                         class="add-document"
                         accept="image/*"
                         ref="doc1"
                         name="frontSide"
                         @change="setFile"
                         @focus="focus = true"
                  >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_8050_7205)">
                      <path d="M16 16L12 12L8 16" stroke="black" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M12 12V21" stroke="#FF0090" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path
                          d="M20.39 18.3905C21.3653 17.8587 22.1358 17.0174 22.5798 15.9991C23.0239 14.9808 23.1162 13.8437 22.8422 12.7672C22.5682 11.6906 21.9434 10.736 21.0666 10.0539C20.1898 9.37185 19.1108 9.00121 18 9.00047H16.74C16.4373 7.82971 15.8731 6.74281 15.0899 5.82147C14.3067 4.90012 13.3248 4.16832 12.2181 3.68108C11.1113 3.19384 9.90851 2.96383 8.70008 3.00835C7.49164 3.05288 6.30903 3.37077 5.24114 3.93814C4.17325 4.5055 3.24787 5.30758 2.53458 6.28405C1.82129 7.26053 1.33865 8.38601 1.12294 9.57587C0.90723 10.7657 0.964065 11.989 1.28917 13.1537C1.61428 14.3185 2.1992 15.3943 2.99996 16.3005"
                          stroke="#FF0090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16 16L12 12L8 16" stroke="#FF0090" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_8050_7205">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </label>
                <img :src="doc1" alt="Preview image">
              </div>

            </div>

            <div class="docs-upload-wrap">

              <label for="kycUploadSecond" class="docs-upload-item" v-if="!doc2">
                <input type="file" id="kycUploadSecond"
                       class="add-document"
                       accept="image/*"
                       ref="doc2"
                       name="backSide"
                       @change="setFile"
                       @focus="focus = true"
                >
                <div class="fake-input__upload">
                  <svg width="75" height="67" viewBox="0 0 75 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M60.2609 18.7868C57.7407 6.14194 45.4471 -2.0654 32.8022 0.454814C23.5536 2.29815 16.322 9.52519 14.473 18.7726C5.50735 19.8059 -0.923829 27.9113 0.108849 36.8775C1.05955 45.1292 8.04863 51.3549 16.355 51.3492H25.0026C26.292 51.3492 27.3372 50.304 27.3372 49.0146C27.3372 47.7252 26.292 46.68 25.0026 46.68H16.355C9.90791 46.6418 4.7132 41.3851 4.75078 34.9385C4.78892 28.492 10.0457 23.2967 16.4928 23.3343C17.6826 23.3348 18.6823 22.4394 18.8132 21.257C19.9984 11.0104 29.2658 3.665 39.5123 4.85024C48.1267 5.84706 54.9222 12.642 55.9184 21.257C56.1143 22.4604 57.1572 23.3422 58.3766 23.3343C64.8237 23.3343 70.0497 28.5603 70.0497 35.0074C70.0497 41.454 64.8237 46.68 58.3766 46.68H49.729C48.4396 46.68 47.3944 47.7252 47.3944 49.0146C47.3944 50.304 48.4396 51.3492 49.729 51.3492H58.3772C67.402 51.2929 74.6729 43.9315 74.6171 34.9061C74.5658 26.6873 68.4187 19.7854 60.2609 18.7868Z"
                        fill="#FF0090"/>
                    <path
                        d="M45.6187 35.8436C46.5148 36.7715 47.9927 36.7972 48.92 35.9011C49.8474 35.0056 49.873 33.5278 48.9775 32.6004C48.9587 32.5805 48.9394 32.5617 48.92 32.5429L39.0191 22.6397C38.1088 21.7265 36.6303 21.7248 35.7172 22.6351C35.7161 22.6368 35.7144 22.638 35.7132 22.6397L25.8123 32.5429C24.8849 33.4384 24.8593 34.9162 25.7548 35.8436C26.6508 36.771 28.1287 36.7966 29.056 35.9011C29.0754 35.8823 29.0948 35.863 29.1135 35.8436L35.0318 29.9231V64.3477C35.0318 65.6371 36.077 66.6823 37.3664 66.6823C38.6553 66.6823 39.7005 65.6371 39.7005 64.3477V29.9231L45.6187 35.8436Z"
                        fill="#FF0090"/>
                  </svg>
                  <p class="fake-input__upload-text">Upload the <span>back</span> <br/>of your document*</p>
                </div>
              </label>

              <div class="kyc-preview-docs" v-else>
                <label for="kycUploadSecondPreview" class="kyc-preview-docs__reload">
                  <input type="file"
                         id="kycUploadSecondPreview"
                         class="add-document"
                         accept="image/*"
                         ref="doc2"
                         name="backSide"
                         @change="setFile"
                         @focus="focus = true"
                  >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_8050_7205)">
                      <path d="M16 16L12 12L8 16" stroke="black" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M12 12V21" stroke="#FF0090" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path
                          d="M20.39 18.3905C21.3653 17.8587 22.1358 17.0174 22.5798 15.9991C23.0239 14.9808 23.1162 13.8437 22.8422 12.7672C22.5682 11.6906 21.9434 10.736 21.0666 10.0539C20.1898 9.37185 19.1108 9.00121 18 9.00047H16.74C16.4373 7.82971 15.8731 6.74281 15.0899 5.82147C14.3067 4.90012 13.3248 4.16832 12.2181 3.68108C11.1113 3.19384 9.90851 2.96383 8.70008 3.00835C7.49164 3.05288 6.30903 3.37077 5.24114 3.93814C4.17325 4.5055 3.24787 5.30758 2.53458 6.28405C1.82129 7.26053 1.33865 8.38601 1.12294 9.57587C0.90723 10.7657 0.964065 11.989 1.28917 13.1537C1.61428 14.3185 2.1992 15.3943 2.99996 16.3005"
                          stroke="#FF0090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16 16L12 12L8 16" stroke="#FF0090" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_8050_7205">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </label>
                <img :src="doc2" alt="Preview image">
              </div>

            </div>


            <div class="docs-upload__error" v-if="!identityCheck">
              <p>Documents cannot be the same</p>
            </div>
            <div class="docs-upload__error" v-if="!isDocsValid">
              <p>You must upload both sides of the document</p>
            </div>
          </div>
          <div class="kyc-mangopay-docs-button">
            <button class="btn" v-if="isDocsValid && identityCheck" @click="uploadDocs">Upload</button>
            <div class="btn disabled" v-else>Upload</div>
          </div>
        </div>

        <div class="kyc-status-wrapper"
             v-if="userTabs.kycTab.kycFlow === 'MANGOPAY_LEGAL_FIRST' || userTabs.kycTab.kycFlow === 'MANGOPAY_LEGAL_SECOND'"
        >

          <StepProgressBar :step-names="companyStepNames"
                           :total-of-steps="companyStepTotal"
                           :current-step="companyStepCurrent"
          />

          <div class="kyc-company__wrap" v-if="companyStepCurrent === 1"  >
            <div class="kyc-company__disclaimer">
              <span class="asterisk">*- Required field</span>
            </div>

            <div class="profile-subtitle-wrap kyc-subtitle">
              <h2 class="profile-subtitle">Company Info</h2>
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{on}">
                  <button
                      v-on="on"
                  >
                    <svg class="tooltip-svg" width="15" height="15" viewBox="0 0 15 15" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                            fill="black"/>
                    </svg>
                  </button>
                </template>
                <span
                    v-html="'In order to verify your legal entity and allow you to use all Djooky functions and prevent any kind of financial fraud we need information about your company.'"></span>
              </v-tooltip>
            </div>
            <div class="kyc-company__section">

              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">Company Name<span class="asterisk">*</span></div>
                <div class="profile-input-wrapper kyc-company__field-wrap">
                  <input name="companyName" type="text" placeholder="None"
                         v-model="companyDataFirstStep.companyName"
                         @blur="$v.companyDataFirstStep.companyName.$touch()"
                  >

                  <p class="error-message"
                     v-if="$v.companyDataFirstStep.companyName.$dirty
                           && !$v.companyDataFirstStep.companyName.required"
                  >Field is required</p>

                </div>
              </div>

              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">Company Number<span class="asterisk">*</span></div>
                <div class="profile-input-wrapper kyc-company__field-wrap">
                  <input name="companyNumber" type="text" placeholder="None"
                         v-model="companyDataFirstStep.companyNumber"
                         @blur="$v.companyDataFirstStep.companyNumber.$touch()"
                  >

                  <p class="error-message"
                     v-if="$v.companyDataFirstStep.companyNumber.$dirty && !$v.companyDataFirstStep.companyNumber.required"
                  >Field is required</p>

                </div>
              </div>

              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">Email<span class="asterisk">*</span></div>
                <div class="profile-input-wrapper kyc-company__field-wrap">
                  <input name="companyEmail" type="email" placeholder="None"
                         v-model="companyDataFirstStep.email"
                         @blur="$v.companyDataFirstStep.email.$touch()"
                  >

                  <p class="error-message"
                     v-if="$v.companyDataFirstStep.email.$dirty && !$v.companyDataFirstStep.email.required"
                  >Field is required</p>

                  <p class="error-message"
                     v-if="$v.companyDataFirstStep.email.$dirty && !$v.companyDataFirstStep.email.email"
                  >You must enter an email address</p>

                </div>
              </div>

            </div>

            <div class="profile-subtitle-wrap kyc-subtitle">
              <h2 class="profile-subtitle">Company address</h2>
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{on}">
                  <button
                      v-on="on"
                  >
                    <svg class="tooltip-svg" width="15" height="15" viewBox="0 0 15 15" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                            fill="black"/>
                    </svg>
                  </button>
                </template>
                <span v-html="'The address at which a business is located.'"></span>
              </v-tooltip>
            </div>
            <div class="kyc-company__section">

              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">Address<span class="asterisk">*</span></div>
                <div class="profile-input-wrapper kyc-company__field-wrap">
                  <input name="companyAddress" type="text" placeholder="None"
                         v-model="companyDataFirstStep.headquartersAddress.addressLine1"
                         @blur="$v.companyDataFirstStep.headquartersAddress.addressLine1.$touch()"
                  >

                  <p class="error-message"
                     v-if="$v.companyDataFirstStep.headquartersAddress.addressLine1.$dirty && !$v.companyDataFirstStep.headquartersAddress.addressLine1.required"
                  >Field is required</p>

                </div>
              </div>

              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">Address 2</div>
                <div class="profile-input-wrapper kyc-company__field-wrap">
                  <input name="companyAddressSecond" type="text" placeholder="None"
                         v-model="companyDataFirstStep.headquartersAddress.addressLine2"
                  >
                </div>
              </div>

              <div class="kyc-company__column-wrap">
                <div class="kyc-company__column-item">
                  <div class="kyc-company__field-title">City<span class="asterisk">*</span></div>
                  <div class="profile-input-wrapper kyc-company__field-wrap">
                    <input name="companyCity" type="text" placeholder="None"
                           v-model="companyDataFirstStep.headquartersAddress.city"
                           @blur="$v.companyDataFirstStep.headquartersAddress.city.$touch()"
                    >

                    <p class="error-message"
                       v-if="$v.companyDataFirstStep.headquartersAddress.city.$dirty && !$v.companyDataFirstStep.headquartersAddress.city.required"
                    >Field is required</p>

                  </div>
                </div>

                <div class="kyc-company__column-item">
                  <div class="kyc-company__field-title">Region/State/Province</div>
                  <div class="profile-input-wrapper kyc-company__field-wrap">
                    <input name="companyRegion" type="text" placeholder="None"
                           v-model="companyDataFirstStep.headquartersAddress.region"
                    >
                  </div>
                </div>

                <div class="kyc-company__column-item">
                  <div class="kyc-company__field-title">Country<span class="asterisk">*</span></div>
                  <CountrySelect class="kyc-company__country-select"
                                 id="id__kyc-country__first"
                                 name="companyAddressCountry"
                                 :options="searchedCountries"
                                 :search-for-code="companyDataFirstStep.headquartersAddress.country"
                                 :placeholder="'Country'"
                                 :identifier="'kyc__company_address'"
                                 @changed="setCompanyAddressCountry"
                                 @searchCheck="searchCheck"
                                 @selectedField="selectedField"
                                 ref="KYCCompanyAddressCountrySelect"
                  />

                  <p class="error-message"
                     v-if="$v.companyDataFirstStep.headquartersAddress.country.$dirty && !$v.companyDataFirstStep.headquartersAddress.country.required"
                  >Field is required</p>

                </div>

                <div class="kyc-company__column-item">
                  <div class="kyc-company__field-title">Postal code<span class="asterisk">*</span></div>
                  <div class="profile-input-wrapper kyc-company__field-wrap">
                    <input name="companyPostalCode" type="text" placeholder="None"
                           v-model="companyDataFirstStep.headquartersAddress.postalCode"
                           @blur="$v.companyDataFirstStep.headquartersAddress.postalCode.$touch()"
                    >

                    <p class="error-message"
                       v-if="$v.companyDataFirstStep.headquartersAddress.postalCode.$dirty && !$v.companyDataFirstStep.headquartersAddress.postalCode.required"
                    >Field is required</p>

                  </div>
                </div>
              </div>
            </div>

            <div class="profile-subtitle-wrap kyc-subtitle">
              <h2 class="profile-subtitle">Legal representative details</h2>
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{on}">
                  <button
                      v-on="on"
                  >
                    <svg class="tooltip-svg" width="15" height="15" viewBox="0 0 15 15" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                            fill="black"/>
                    </svg>
                  </button>
                </template>
                <span
                    v-html="'Information about a person who is acting on behalf of the company personally or under the power of attorney and legally represents the company. It could be CEO, executive director, general manager etc.'"></span>
              </v-tooltip>
            </div>

            <div class="kyc-company__section">
              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">First Name<span class="asterisk">*</span></div>
                <div class="profile-input-wrapper kyc-company__field-wrap">
                  <input name="firstName" type="text" placeholder="None"
                         v-model="companyDataFirstStep.legalRepresentativeFirstName"
                         @blur="$v.companyDataFirstStep.legalRepresentativeFirstName.$touch()"
                  >

                  <p class="error-message"
                     v-if="$v.companyDataFirstStep.legalRepresentativeFirstName.$dirty && !$v.companyDataFirstStep.legalRepresentativeFirstName.required"
                  >Field is required</p>

                </div>
              </div>

              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">Last Name<span class="asterisk">*</span></div>
                <div class="profile-input-wrapper kyc-company__field-wrap">
                  <input name="lastName" type="text" placeholder="None"
                         v-model="companyDataFirstStep.legalRepresentativeLastName"
                         @blur="$v.companyDataFirstStep.legalRepresentativeLastName.$touch()"
                  >

                  <p class="error-message"
                     v-if="$v.companyDataFirstStep.legalRepresentativeLastName.$dirty && !$v.companyDataFirstStep.legalRepresentativeLastName.required"
                  >Field is required</p>

                </div>
              </div>

              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">Nationality<span class="asterisk">*</span></div>
                <div class="profile-input-wrapper kyc-company__field-wrap">
                  <!--                    <input name="nationality" type="text" placeholder="None"-->
                  <!--                           v-model="companyDataFirstStep.legalRepresentativeNationality"-->
                  <!--                           @blur="$v.companyDataFirstStep.legalRepresentativeNationality.$touch()"-->
                  <!--                    >-->

                  <CountrySelect class="kyc-company__country-select"
                                 name="nationality"
                                 :options="searchedNationalities"
                                 :search-for-code="companyDataFirstStep.legalRepresentativeNationality"
                                 :placeholder="'Nationality'"
                                 :identifier="'kyc__legal_nationality'"
                                 @changed="setCompanyNationality"
                                 @searchCheck="searchCheck"
                                 @selectedField="selectedField"
                                 ref="KYCCompanyLegalDetailsNationality"
                  />

                  <p class="error-message"
                     v-if="$v.companyDataFirstStep.legalRepresentativeNationality.$dirty && !$v.companyDataFirstStep.legalRepresentativeNationality.required"
                  >Field is required</p>

                </div>
              </div>

              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">Country of residence<span class="asterisk">*</span></div>
                <div class="profile-input-wrapper kyc-company__field-wrap">
                  <CountrySelect class="kyc-company__country-select"
                                 id="id__kyc-country__second"
                                 name="companyLegalDetailsCountry"
                                 :options="searchedCountries"
                                 :search-for-code="companyDataFirstStep.legalRepresentativeCountryOfResidence"
                                 :placeholder="'Country'"
                                 :identifier="'kyc__legal_details'"
                                 @changed="setCompanyResidenceCountry"
                                 @searchCheck="searchCheck"
                                 @selectedField="selectedField"
                                 ref="KYCCompanyLegalDetailsCountrySelect"
                  />

                  <p class="error-message"
                     v-if="$v.companyDataFirstStep.legalRepresentativeCountryOfResidence.$dirty && !$v.companyDataFirstStep.legalRepresentativeCountryOfResidence.required"
                  >Field is required</p>

                </div>
              </div>

              <div class="kyc-company__row-wrap kyc-company__row-date-of-birth">
                <div class="kyc-company__field-title">Date of birth<span class="asterisk">*</span></div>
                <div class="profile-input-wrapper kyc-company__field-wrap kyc-company__field-date-of-birth">

                  <v-menu
                      ref="KYCCompanyFirstStepMenu"
                      v-model="menuDateFirst"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="companyDataFirstStep.legalRepresentativeBirthday"
                          label="YYYY.MM.DD"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="companyDataFirstStep.legalRepresentativeBirthday"
                        :active-picker.sync="activePickerFirst"
                        :max="(new Date(new Date().setFullYear(new Date().getFullYear() - 18))).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="saveDate"
                    ></v-date-picker>
                  </v-menu>

                  <p class="error-message"
                     v-if="$v.companyDataFirstStep.legalRepresentativeBirthday.$dirty && !$v.companyDataFirstStep.legalRepresentativeBirthday.required"
                  >Field is required</p>

                </div>
              </div>
            </div>

            <div class="profile-subtitle-wrap kyc-subtitle">
              <h2 class="profile-subtitle">Legal representative address</h2>
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{on}">
                  <button
                      v-on="on"
                  >
                    <svg class="tooltip-svg" width="15" height="15" viewBox="0 0 15 15" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                            fill="black"/>
                    </svg>
                  </button>
                </template>
                <span v-html="'Official registered address of the legal representative of the company.'"></span>
              </v-tooltip>
            </div>

            <div class="kyc-company__section">

              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">Address<span class="asterisk">*</span></div>
                <div class="profile-input-wrapper kyc-company__field-wrap">
                  <input name="companyAddress" type="text" placeholder="None"
                         v-model="companyDataFirstStep.legalRepresentativeAddress.addressLine1"
                         @blur="$v.companyDataFirstStep.legalRepresentativeAddress.addressLine1.$touch()"
                  >

                  <p class="error-message"
                     v-if="$v.companyDataFirstStep.legalRepresentativeAddress.addressLine1.$dirty && !$v.companyDataFirstStep.legalRepresentativeAddress.addressLine1.required"
                  >Field is required</p>

                </div>
              </div>

              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">Address 2</div>
                <div class="profile-input-wrapper kyc-company__field-wrap">
                  <input name="companyAddressSecond" type="text" placeholder="None"
                         v-model="companyDataFirstStep.legalRepresentativeAddress.addressLine2"
                  >
                </div>
              </div>

              <div class="kyc-company__column-wrap">
                <div class="kyc-company__column-item">
                  <div class="kyc-company__field-title">City<span class="asterisk">*</span></div>
                  <div class="profile-input-wrapper kyc-company__field-wrap">
                    <input name="companyCity" type="text" placeholder="None"
                           v-model="companyDataFirstStep.legalRepresentativeAddress.city"
                           @blur="$v.companyDataFirstStep.legalRepresentativeAddress.city.$touch()"
                    >

                    <p class="error-message"
                       v-if="$v.companyDataFirstStep.legalRepresentativeAddress.city.$dirty && !$v.companyDataFirstStep.legalRepresentativeAddress.city.required"
                    >Field is required</p>

                  </div>
                </div>

                <div class="kyc-company__column-item">
                  <div class="kyc-company__field-title">Region/State/Province</div>
                  <div class="profile-input-wrapper kyc-company__field-wrap">
                    <input name="companyRegion" type="text" placeholder="None"
                           v-model="companyDataFirstStep.legalRepresentativeAddress.region"
                    >
                  </div>
                </div>

                <div class="kyc-company__column-item">
                  <div class="kyc-company__field-title">Country<span class="asterisk">*</span></div>
                  <CountrySelect class="kyc-company__country-select"
                                 id="id__kyc-country__third"
                                 name="companyAddressRepresentativeCountry"
                                 :options="searchedCountries"
                                 :search-for-code="companyDataFirstStep.legalRepresentativeAddress.country"
                                 :placeholder="'Country'"
                                 :identifier="'kyc__representative_address'"
                                 @changed="setCompanyLegalAddressCountry"
                                 @searchCheck="searchCheck"
                                 @selectedField="selectedField"
                                 ref="KYCCompanyAddressRepresentativeCountrySelect"
                  />

                  <p class="error-message"
                     v-if="$v.companyDataFirstStep.legalRepresentativeAddress.country.$dirty && !$v.companyDataFirstStep.legalRepresentativeAddress.country.required"
                  >Field is required</p>

                </div>

                <div class="kyc-company__column-item">
                  <div class="kyc-company__field-title">Postal code<span class="asterisk">*</span></div>
                  <div class="profile-input-wrapper kyc-company__field-wrap">
                    <input name="companyPostalCode" type="text" placeholder="None"
                           v-model="companyDataFirstStep.legalRepresentativeAddress.postalCode"
                           @blur="$v.companyDataFirstStep.legalRepresentativeAddress.postalCode.$touch()"
                    >

                    <p class="error-message"
                       v-if="$v.companyDataFirstStep.legalRepresentativeAddress.postalCode.$dirty && !$v.companyDataFirstStep.legalRepresentativeAddress.postalCode.required"
                    >Field is required</p>

                  </div>
                </div>
              </div>

            </div>
            <button class="btn kyc-company__submit-btn"
                    @click="submitCompanyFirstStep"
            >Save and continue
            </button>
          </div>

          <div class="kyc-company__wrap kyc-company__reg-docs-wrap" v-if="companyStepCurrent === 2">

            <div class="profile-subtitle-wrap kyc-subtitle">
              <h2 class="profile-subtitle">Registration documents</h2>
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{on}">
                  <button
                      v-on="on"
                  >
                    <svg class="tooltip-svg" width="15" height="15" viewBox="0 0 15 15" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                            fill="black"/>
                    </svg>
                  </button>
                </template>
                <span
                    v-html="'Set of legally certified company documents that contain information on the formation, constitution, and legal structure of your company.'"></span>
              </v-tooltip>
            </div>

            <div class="kyc-company__section kyc-company__section-reg-docs">

              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">
                  <span class="kyc-company__field-title-text">Identity proof</span>
                  <v-tooltip bottom max-width="300">
                    <template v-slot:activator="{on}">
                      <button
                          v-on="on"
                      >
                        <svg class="tooltip-svg" width="15" height="15" viewBox="0 0 15 15" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                                fill="black"/>
                        </svg>
                      </button>
                    </template>
                    <span
                        v-html="'A legal document used to verify identity of the legal representative of the company (Passport, ID, driver license).'"></span>
                  </v-tooltip>
                </div>
                <label for="kyc-company__identity_input"
                       class="kyc-company__file-field"
                       :class="{'file-field__valid': secondStepIdentityProof}"
                >
                    <span class="file-field__upload-item">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_8476_8543)">
                          <path d="M16 16L12 12L8 16" stroke="black" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path d="M12 12V21" stroke="#FE0190" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path
                              d="M20.39 18.39C21.3653 17.8583 22.1358 17.0169 22.5799 15.9986C23.0239 14.9804 23.1162 13.8432 22.8422 12.7667C22.5682 11.6901 21.9435 10.7355 21.0667 10.0534C20.1899 9.37137 19.1109 9.00072 18 8.99998H16.74C16.4373 7.82923 15.8732 6.74232 15.09 5.82098C14.3067 4.89964 13.3249 4.16783 12.2181 3.68059C11.1114 3.19335 9.90856 2.96334 8.70012 3.00787C7.49169 3.05239 6.30907 3.37028 5.24118 3.93765C4.17329 4.50501 3.24792 5.30709 2.53463 6.28357C1.82134 7.26004 1.3387 8.38552 1.12299 9.57538C0.907276 10.7652 0.964111 11.9885 1.28922 13.1532C1.61433 14.318 2.19925 15.3938 3.00001 16.3"
                              stroke="#FE0190" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M16 16L12 12L8 16" stroke="#FE0190" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_8476_8543">
                            <rect width="24" height="24" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  <span class="kyc-company__file-field-text"
                        v-text="fakeFirstFileData ? fakeFirstFileData : 'Choose file...'"
                  ></span>
                  <span class="file-field__valid-text">File has been approved</span>
                  <input class="kyc-company__file_input"
                         id="kyc-company__identity_input"
                         name="identifyDoc"
                         type="file"
                         accept="application/pdf"
                         @change="setCompanyFile"
                         :disabled="secondStepIdentityProof"
                  >

                  <div class="file-field__valid-icon" v-if="!secondStepIdentityProof">
                    <svg v-if="$v.fakeFirstFileData.required"
                         width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#6FC099" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16 10L11.5562 15L9 12.2028" stroke="#6FC099" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                    <svg v-else
                         width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#C06F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 8V12" stroke="#C06F6F" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M12 16H12.01" stroke="#C06F6F" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </div>

                  <div class="file-field__valid-icon" v-else>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#6FC099" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16 10L11.5562 15L9 12.2028" stroke="#6FC099" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </div>

                  <span class="file-field__type">PDF</span>

                </label>
              </div>

              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">
                  <span class="kyc-company__field-title-text">Articles of association</span>
                  <v-tooltip bottom max-width="300">
                    <template v-slot:activator="{on}">
                      <button
                          v-on="on"
                      >
                        <svg class="tooltip-svg" width="15" height="15" viewBox="0 0 15 15" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                                fill="black"/>
                        </svg>
                      </button>
                    </template>
                    <span
                        v-html="'A set of rules governing the operations, management and ownership of your company.'"></span>
                  </v-tooltip>
                </div>
                <label for="kyc-company__articles_input"
                       class="kyc-company__file-field"
                       :class="{'file-field__valid': secondStepArticlesOfAssociation}"
                >
                    <span class="file-field__upload-item">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_8476_8543)">
                          <path d="M16 16L12 12L8 16" stroke="black" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path d="M12 12V21" stroke="#FE0190" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path
                              d="M20.39 18.39C21.3653 17.8583 22.1358 17.0169 22.5799 15.9986C23.0239 14.9804 23.1162 13.8432 22.8422 12.7667C22.5682 11.6901 21.9435 10.7355 21.0667 10.0534C20.1899 9.37137 19.1109 9.00072 18 8.99998H16.74C16.4373 7.82923 15.8732 6.74232 15.09 5.82098C14.3067 4.89964 13.3249 4.16783 12.2181 3.68059C11.1114 3.19335 9.90856 2.96334 8.70012 3.00787C7.49169 3.05239 6.30907 3.37028 5.24118 3.93765C4.17329 4.50501 3.24792 5.30709 2.53463 6.28357C1.82134 7.26004 1.3387 8.38552 1.12299 9.57538C0.907276 10.7652 0.964111 11.9885 1.28922 13.1532C1.61433 14.318 2.19925 15.3938 3.00001 16.3"
                              stroke="#FE0190" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M16 16L12 12L8 16" stroke="#FE0190" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_8476_8543">
                            <rect width="24" height="24" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  <span class="kyc-company__file-field-text"
                        v-text="fakeSecondFileData ? fakeSecondFileData : 'Choose file...'"
                  ></span>
                  <span class="file-field__valid-text">File has been approved</span>
                  <input class="kyc-company__file_input"
                         id="kyc-company__articles_input"
                         name="statuteDoc"
                         type="file"
                         accept="application/pdf"
                         @change="setCompanyFile"
                         :disabled="secondStepArticlesOfAssociation"
                  >
                  <div class="file-field__valid-icon" v-if="!secondStepArticlesOfAssociation">
                    <svg v-if="$v.fakeSecondFileData.required"
                         width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#6FC099" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16 10L11.5562 15L9 12.2028" stroke="#6FC099" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                    <svg v-else
                         width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#C06F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 8V12" stroke="#C06F6F" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M12 16H12.01" stroke="#C06F6F" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </div>

                  <div class="file-field__valid-icon" v-else>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#6FC099" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16 10L11.5562 15L9 12.2028" stroke="#6FC099" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </div>

                  <span class="file-field__type">PDF</span>

                </label>
              </div>

              <div class="kyc-company__row-wrap">
                <div class="kyc-company__field-title">
                  <span class="kyc-company__field-title-text">Registration proof</span>
                  <v-tooltip bottom max-width="300">
                    <template v-slot:activator="{on}">
                      <button
                          v-on="on"
                      >
                        <svg class="tooltip-svg" width="15" height="15" viewBox="0 0 15 15" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                                fill="black"/>
                        </svg>
                      </button>
                    </template>
                    <span v-html="'Extract from the Company Register issued within the last three months.'"></span>
                  </v-tooltip>
                </div>
                <label for="kyc-company__registration_input"
                       class="kyc-company__file-field"
                       :class="{'file-field__valid': secondStepIRegistrationProof}"
                >
                    <span class="file-field__upload-item">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_8476_8543)">
                          <path d="M16 16L12 12L8 16" stroke="black" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path d="M12 12V21" stroke="#FE0190" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path
                              d="M20.39 18.39C21.3653 17.8583 22.1358 17.0169 22.5799 15.9986C23.0239 14.9804 23.1162 13.8432 22.8422 12.7667C22.5682 11.6901 21.9435 10.7355 21.0667 10.0534C20.1899 9.37137 19.1109 9.00072 18 8.99998H16.74C16.4373 7.82923 15.8732 6.74232 15.09 5.82098C14.3067 4.89964 13.3249 4.16783 12.2181 3.68059C11.1114 3.19335 9.90856 2.96334 8.70012 3.00787C7.49169 3.05239 6.30907 3.37028 5.24118 3.93765C4.17329 4.50501 3.24792 5.30709 2.53463 6.28357C1.82134 7.26004 1.3387 8.38552 1.12299 9.57538C0.907276 10.7652 0.964111 11.9885 1.28922 13.1532C1.61433 14.318 2.19925 15.3938 3.00001 16.3"
                              stroke="#FE0190" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M16 16L12 12L8 16" stroke="#FE0190" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_8476_8543">
                            <rect width="24" height="24" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  <span class="kyc-company__file-field-text"
                        v-text="fakeThirdFileData ? fakeThirdFileData : 'Choose file...'"
                  ></span>
                  <span class="file-field__valid-text">File has been approved</span>
                  <input class="kyc-company__file_input"
                         id="kyc-company__registration_input"
                         name="registrationDoc"
                         type="file"
                         accept="application/pdf"
                         @change="setCompanyFile"
                         :disabled="secondStepIRegistrationProof"
                  >

                  <div class="file-field__valid-icon" v-if="!secondStepIRegistrationProof">
                    <svg v-if="$v.fakeThirdFileData.required"
                         width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#6FC099" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16 10L11.5562 15L9 12.2028" stroke="#6FC099" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                    <svg v-else
                         width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#C06F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 8V12" stroke="#C06F6F" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M12 16H12.01" stroke="#C06F6F" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </div>

                  <div class="file-field__valid-icon" v-else>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#6FC099" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16 10L11.5562 15L9 12.2028" stroke="#6FC099" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </div>

                  <span class="file-field__type">PDF</span>

                </label>
              </div>

              <div class="kyc-company__row-wrap shareholders">
                <div class="kyc-company__field-title">
                  <div class="kyc-company__field-title-text">Shareholder declaration</div>
                  <v-tooltip bottom max-width="300">
                    <template v-slot:activator="{on}">
                      <button
                          v-on="on"
                      >
                        <svg class="tooltip-svg" width="15" height="15" viewBox="0 0 15 15" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                                fill="black"/>
                        </svg>
                      </button>
                    </template>
                    <span v-html="'Please complete the shareholder declaration following the link: <a>1</a>'"></span>
                  </v-tooltip>
                </div>
                <label for="kyc-company__shreholders_input"
                       class="kyc-company__file-field"
                       :class="{'file-field__valid': secondStepIShareholderDeclaration}"
                >
                    <span class="file-field__upload-item">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_8476_8543)">
                          <path d="M16 16L12 12L8 16" stroke="black" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path d="M12 12V21" stroke="#FE0190" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path
                              d="M20.39 18.39C21.3653 17.8583 22.1358 17.0169 22.5799 15.9986C23.0239 14.9804 23.1162 13.8432 22.8422 12.7667C22.5682 11.6901 21.9435 10.7355 21.0667 10.0534C20.1899 9.37137 19.1109 9.00072 18 8.99998H16.74C16.4373 7.82923 15.8732 6.74232 15.09 5.82098C14.3067 4.89964 13.3249 4.16783 12.2181 3.68059C11.1114 3.19335 9.90856 2.96334 8.70012 3.00787C7.49169 3.05239 6.30907 3.37028 5.24118 3.93765C4.17329 4.50501 3.24792 5.30709 2.53463 6.28357C1.82134 7.26004 1.3387 8.38552 1.12299 9.57538C0.907276 10.7652 0.964111 11.9885 1.28922 13.1532C1.61433 14.318 2.19925 15.3938 3.00001 16.3"
                              stroke="#FE0190" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M16 16L12 12L8 16" stroke="#FE0190" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_8476_8543">
                            <rect width="24" height="24" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  <span class="kyc-company__file-field-text"
                        v-text="fakeFourthFileData ? fakeFourthFileData : 'Choose file...'"
                  ></span>
                  <span class="file-field__valid-text">File has been approved</span>
                  <input class="kyc-company__file_input"
                         id="kyc-company__shreholders_input"
                         name="shareholderDoc"
                         type="file"
                         accept="application/pdf"
                         @change="setCompanyFile"
                         :disabled="secondStepIShareholderDeclaration"
                  >

                  <div class="file-field__valid-icon" v-if="!secondStepIShareholderDeclaration">
                    <svg v-if="$v.fakeFourthFileData.required"
                         width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#6FC099" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16 10L11.5562 15L9 12.2028" stroke="#6FC099" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                    <svg v-else
                         width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#C06F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 8V12" stroke="#C06F6F" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M12 16H12.01" stroke="#C06F6F" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </div>

                  <div class="file-field__valid-icon" v-else>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#6FC099" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16 10L11.5562 15L9 12.2028" stroke="#6FC099" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </div>

                  <span class="file-field__type">PDF</span>

                </label>
              </div>

            </div>
            <button class="btn kyc-company__submit-btn"
                    :class="{'disabled-btn': disableSecondSubmit}"
                    :disabled="disableSecondSubmit"
                    @click="submitCompanySecondStep"
            >Next
            </button>
          </div>

          <div class="kyc-company__wrap" v-if="companyStepCurrent === 3">

            <div class="kyc-company__disclaimer">
              <span class="asterisk">*- Required field</span>

              <p class="kyc-company__reject-reason"
                 v-if="companyStepStatus.uboInfo && companyStepStatus.uboInfo.reason === 'WRONG_UBO_INFORMATION'"
              >You entered incorrect information last time. Please enter correct data.</p>

              <p class="kyc-company__reject-reason"
                 v-if="companyStepStatus.uboInfo && companyStepStatus.uboInfo.reason === 'DECLARATION_DO_NOT_MATCH_UBO_INFORMATION'"
              >At least one piece of information doesn't match the provided legal documents data.</p>

            </div>

            <transition-group name="fade" tag="div">

              <div class="kyc-company__beneficial-container"
                   v-for="(vueEl, index) in $v.companyDataThirdStep.ubos.$each.$iter"
                   :key="index"
              >

                <div class="profile-subtitle-wrap kyc-subtitle">
                  <h2 class="profile-subtitle">Beneficial Owner {{ +index + 1 }}:</h2>
                  <div class="kyc-subtitle__btn-wrap">
                    <v-tooltip bottom max-width="300">
                      <template v-slot:activator="{on}">
                        <button
                            v-on="on"
                        >
                          <svg class="tooltip-svg" width="15" height="15" viewBox="0 0 15 15" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                                  fill="black"/>
                          </svg>
                        </button>
                      </template>
                      <span
                          v-html="'Please provide information about Ultimate beneficiary owner (UBO). It is an individual who owns an interest of more than 25% of the capital in the entity or has direct or indirect control or power of decision in the entity or one who exercises 25% or more of the voting rights.'"></span>
                    </v-tooltip>
                    <button class="kyc-company__delete-beneficial"
                            v-if="companyDataThirdStep.ubos.length > 1 && index > 0"
                            @click="deleteBeneficial(index)"
                    >
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.125 4.25H3.54167H14.875" stroke="#BF6464" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path
                            d="M5.66666 4.24984V2.83317C5.66666 2.45745 5.81591 2.09711 6.08159 1.83144C6.34727 1.56576 6.7076 1.4165 7.08332 1.4165H9.91666C10.2924 1.4165 10.6527 1.56576 10.9184 1.83144C11.1841 2.09711 11.3333 2.45745 11.3333 2.83317V4.24984M13.4583 4.24984V14.1665C13.4583 14.5422 13.3091 14.9026 13.0434 15.1682C12.7777 15.4339 12.4174 15.5832 12.0417 15.5832H4.95832C4.5826 15.5832 4.22227 15.4339 3.95659 15.1682C3.69091 14.9026 3.54166 14.5422 3.54166 14.1665V4.24984H13.4583Z"
                            stroke="#BF6464" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.08334 7.7915V12.0415" stroke="#BF6464" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M9.91666 7.7915V12.0415" stroke="#BF6464" stroke-linecap="round"
                              stroke-linejoin="round"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="kyc-company__section">

                  <div class="kyc-company__row-wrap">
                    <div class="kyc-company__field-title">First Name<span class="asterisk">*</span></div>
                    <div class="profile-input-wrapper kyc-company__field-wrap">
                      <input name="firstName" type="text" placeholder="None"
                             v-model="vueEl.firstName.$model"
                             @blur="vueEl.firstName.$touch()"
                      >

                      <p class="error-message"
                         v-if="vueEl.firstName.$dirty
                           && !vueEl.firstName.required"
                      >Field is required</p>

                    </div>
                  </div>

                  <div class="kyc-company__row-wrap">
                    <div class="kyc-company__field-title">Last Name<span class="asterisk">*</span></div>
                    <div class="profile-input-wrapper kyc-company__field-wrap">
                      <input name="lastName" type="text" placeholder="None"
                             v-model="vueEl.lastName.$model"
                             @blur="vueEl.lastName.$touch()"
                      >

                      <p class="error-message"
                         v-if="vueEl.lastName.$dirty
                           && !vueEl.lastName.required"
                      >Field is required</p>

                    </div>
                  </div>

                  <div class="kyc-company__row-wrap">
                    <div class="kyc-company__field-title">Nationality<span class="asterisk">*</span></div>
                    <div class="profile-input-wrapper kyc-company__field-wrap">

                      <CountrySelect class="kyc-company__country-select"
                                     name="companyAddressCountry"
                                     :options="searchedNationalities"
                                     :search-for-code="vueEl.nationality.$model"
                                     :placeholder="'Nationality'"
                                     :identifier="`kyc__beneficial-nationality-index:${index}`"
                                     @changed="setCompanyBeneficialCountry"
                                     @searchCheck="searchCheck"
                                     @selectedField="selectedField"
                                     :ref="`KYCCompanyBeneficialNationalityID${index}`"
                      />

                      <p class="error-message"
                         v-if="vueEl.nationality.$dirty
                           && !vueEl.nationality.required"
                      >Field is required</p>

                    </div>
                  </div>

                  <!--                    <div class="kyc-company__row-wrap">-->
                  <!--                      <div class="kyc-company__field-title">Country of residence<span class="asterisk">*</span></div>-->
                  <!--                      <div class="profile-input-wrapper kyc-company__field-wrap">-->

                  <!--                        <input type="text" placeholder="Country" disabled readonly>-->

                  <!--                        <CountrySelect class="kyc-company__country-select"-->
                  <!--                                       id="id__kyc-country__fourth"-->
                  <!--                                       name="companyCountryResidence"-->
                  <!--                                       :options="searchedCountries"-->
                  <!--                                       :search-for-code="vueEl"-->
                  <!--                                       :placeholder="'Country'"-->
                  <!--                                       :identifier="`kyc__beneficial-country-index:${index}`"-->
                  <!--                                       @changed="setCompanyResidenceCountry"-->
                  <!--                                       @searchCheck="searchCheck"-->
                  <!--                                       @selectedField="selectedField"-->
                  <!--                                       :ref="`KYCCompanyBeneficialCountryID${index}`"-->
                  <!--                        />-->

                  <!--                        <p class="error-message"-->
                  <!--                           v-if="vueEl.uboId.$dirty-->
                  <!--                             && !vueEl.uboId.required"-->
                  <!--                        >Field is required</p>-->

                  <!--                      </div>-->
                  <!--                    </div>-->

                  <div class="kyc-company__row-wrap kyc-company__row-date-of-birth">
                    <div class="kyc-company__field-title">Date of birth<span class="asterisk">*</span></div>
                    <div class="profile-input-wrapper kyc-company__field-wrap kyc-company__field-date-of-birth">

                      <v-menu
                          :ref="`KYCCompanyThirdStepMenu-index:${index}`"
                          v-model="vueEl.menuDateUbo.$model"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="vueEl.birthday.$model"
                              label="YYYY.MM.DD"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="vueEl.birthday.$model"
                            :max="(new Date(new Date().setFullYear(new Date().getFullYear() - 18))).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="saveDatePickerThirdStep(index, $event)"
                        ></v-date-picker>
                      </v-menu>

                      <p class="error-message"
                         v-if="vueEl.birthday.$dirty
                           && !vueEl.birthday.required"
                      >Field is required</p>

                    </div>
                  </div>

                </div>

                <div class="profile-subtitle-wrap kyc-subtitle">
                  <h2 class="profile-subtitle">Beneficial address</h2>
                  <v-tooltip bottom max-width="300">
                    <template v-slot:activator="{on}">
                      <button
                          v-on="on"
                      >
                        <svg class="tooltip-svg" width="15" height="15" viewBox="0 0 15 15" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"
                                fill="black"/>
                        </svg>
                      </button>
                    </template>
                    <span v-html="'Official registered address of the UBO.'"></span>
                  </v-tooltip>
                </div>
                <div class="kyc-company__section">

                  <div class="kyc-company__row-wrap">
                    <div class="kyc-company__field-title">Address 1<span class="asterisk">*</span></div>
                    <div class="profile-input-wrapper kyc-company__field-wrap">
                      <input name="companyAddressFirth" type="text" placeholder="None"
                             v-model="vueEl.address.addressLine1.$model"
                             @blur="vueEl.address.addressLine1.$touch()"
                      >

                      <p class="error-message"
                         v-if="vueEl.address.addressLine1.$dirty
                           && !vueEl.address.addressLine1.required"
                      >Field is required</p>

                    </div>
                  </div>

                  <div class="kyc-company__row-wrap">
                    <div class="kyc-company__field-title">Address 2</div>
                    <div class="profile-input-wrapper kyc-company__field-wrap">
                      <input name="companyAddressSecond" type="text" placeholder="None"
                             v-model="vueEl.address.addressLine2.$model"
                      >
                    </div>
                  </div>

                  <div class="kyc-company__column-wrap">
                    <div class="kyc-company__column-item">
                      <div class="kyc-company__field-title">City<span class="asterisk">*</span></div>
                      <div class="profile-input-wrapper kyc-company__field-wrap">
                        <input name="companyCity" type="text" placeholder="None"
                               v-model="vueEl.address.city.$model"
                               @blur="vueEl.address.city.$touch()"
                        >

                        <p class="error-message"
                           v-if="vueEl.address.city.$dirty
                           && !vueEl.address.city.required"
                        >Field is required</p>

                      </div>
                    </div>

                    <div class="kyc-company__column-item">
                      <div class="kyc-company__field-title">Region/State/Province</div>
                      <div class="profile-input-wrapper kyc-company__field-wrap">
                        <input name="companyRegion" type="text" placeholder="None"
                               v-model="vueEl.address.region.$model"
                        >
                      </div>
                    </div>

                    <div class="kyc-company__column-item">
                      <div class="kyc-company__field-title">Country<span class="asterisk">*</span></div>
                      <CountrySelect class="kyc-company__country-select"
                                     name="companyAddressCountry"
                                     :options="searchedCountries"
                                     :search-for-code="vueEl.address.country.$model"
                                     :placeholder="'Country'"
                                     :identifier="`kyc__beneficial-address_country-index:${index}`"
                                     @changed="setCompanyBeneficialCountry"
                                     @searchCheck="searchCheck"
                                     @selectedField="selectedField"
                                     :ref="`KYCCompanyBeneficialAddressCountryID${index}`"
                      />

                      <p class="error-message"
                         v-if="vueEl.address.country.$dirty
                           && !vueEl.address.country.required"
                      >Field is required</p>

                    </div>

                    <div class="kyc-company__column-item">
                      <div class="kyc-company__field-title">Postal code<span class="asterisk">*</span></div>
                      <div class="profile-input-wrapper kyc-company__field-wrap">
                        <input name="companyPostalCode" type="text" placeholder="None"
                               v-model="vueEl.address.postalCode.$model"
                               @blur="vueEl.address.postalCode.$touch()"
                        >

                        <p class="error-message"
                           v-if="vueEl.address.postalCode.$dirty
                           && !vueEl.address.postalCode.required"
                        >Field is required</p>

                      </div>
                    </div>
                  </div>
                </div>

                <div class="profile-subtitle-wrap kyc-subtitle">
                  <h2 class="profile-subtitle">Birthplace</h2>
                  <!--                    <v-tooltip bottom max-width="300">-->
                  <!--                      <template v-slot:activator="{on}">-->
                  <!--                        <button-->
                  <!--                            v-on="on"-->
                  <!--                        >-->
                  <!--                          <svg class="tooltip-svg" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                  <!--                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z"-->
                  <!--                                  fill="black"/>-->
                  <!--                          </svg>-->
                  <!--                        </button>-->
                  <!--                      </template>-->
                  <!--                      <span v-html="'text'"></span>-->
                  <!--                    </v-tooltip>-->
                </div>
                <div class="kyc-company__section">

                  <div class="kyc-company__row-wrap">
                    <div class="kyc-company__field-title">City<span class="asterisk">*</span></div>
                    <div class="profile-input-wrapper kyc-company__field-wrap">
                      <input name="companyBirthPlaceCity" type="text" placeholder="None"
                             v-model="vueEl.birthplaceCity.$model"
                             @blur="vueEl.birthplaceCity.$touch()"
                      >

                      <p class="error-message"
                         v-if="vueEl.birthplaceCity.$dirty
                           && !vueEl.birthplaceCity.required"
                      >Field is required</p>

                    </div>
                  </div>

                  <div class="kyc-company__row-wrap">
                    <div class="kyc-company__field-title">Country<span class="asterisk">*</span></div>
                    <div class="profile-input-wrapper kyc-company__field-wrap">
                      <CountrySelect class="kyc-company__country-select"
                                     name="companyBirthPlaceCountry"
                                     :options="searchedCountries"
                                     :search-for-code="vueEl.birthplaceCountry.$model"
                                     :placeholder="'Country'"
                                     :identifier="`kyc__beneficial-birthplace_country-index:${index}`"
                                     @changed="setCompanyBeneficialCountry"
                                     @searchCheck="searchCheck"
                                     @selectedField="selectedField"
                                     :ref="`KYCCompanyBeneficialBirthPlaceCountryID${index}`"

                      />

                      <p class="error-message"
                         v-if="vueEl.birthplaceCountry.$dirty
                           && !vueEl.birthplaceCountry.required"
                      >Field is required</p>

                    </div>
                  </div>

                </div>

              </div>

            </transition-group>

            <div class="profile-subtitle-wrap kyc-subtitle subtitle-beneficial-wrap">
              <button class="profile-subtitle add-beneficial-btn"
                      @click="addBeneficial"
              >+Add Beneficial
              </button>

              <transition name="fade" tag="p">
                <p class="error-message beneficial-error"
                   v-if="beneficialLengthError"
                >
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        stroke="#C06F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 8V12" stroke="#C06F6F" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M12 16H12.01" stroke="#C06F6F" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </svg>
                  <span>There can be no more than 4 beneficiaries</span>
                </p>
              </transition>

            </div>

            <button class="btn kyc-company__submit-btn"
                    @click="submitCompanyThirdStep"
            >Save and continue
            </button>

          </div>

        </div>

        <div class="kyc-status-wrapper kyc__in_progress" v-if="inProgressFlag">

          <StepProgressBar :step-names="companyStepNames"
                           :total-of-steps="companyStepTotal"
                           :current-step="companyStepCurrent"
                           :all-done="tabs.kycTab.kycFlow === 'IN_PROGRESS'"
          />

          <div class="kyc-company__in_progress-container">
            <div class="kyc-company__in_progress">
              <div class="kyc-company__in_progress-icon">
                <svg width="34" height="44" viewBox="0 0 34 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1H33" stroke="#FF0090" stroke-width="2" stroke-linecap="round"/>
                  <path d="M1 42.1428H33" stroke="#FF0090" stroke-width="2" stroke-linecap="round"/>
                  <path d="M15.3375 21.1671C8.52369 21.1671 3 12.138 3 1" stroke="#FF0090" stroke-width="2"
                        stroke-linecap="round"/>
                  <path d="M18.6596 21.1671C25.4734 21.1671 30.9971 12.138 30.9971 1" stroke="#FF0090" stroke-width="2"
                        stroke-linecap="round"/>
                  <path d="M15.3375 21.1674C8.52369 21.1674 3 30.1965 3 41.3345" stroke="#FF0090" stroke-width="2"
                        stroke-linecap="round"/>
                  <path d="M18.6596 21.1674C25.4734 21.1674 30.9971 30.1965 30.9971 41.3345" stroke="#FF0090"
                        stroke-width="2" stroke-linecap="round"/>
                </svg>
              </div>

              <div class="kyc-company__in_progress-text-block">
                <p class="kyc-company__in_progress-title">Thank you</p>
                <p class="kyc-company__in_progress-text">We are currently checking your data.</p>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="preloader-wrapper profile-preloader" v-else>
        <v-progress-circular
            :size="75"
            :width="8"
            color="#FF0090"
            indeterminate
        ></v-progress-circular>
      </div>

    </div>
    <ServerErrorPopup ref="serverErrorPopup" :errorMessage="error.errorMessage"/>
  </div>
</template>

<script>
import snsWebSdk from '@sumsub/websdk';
import {mapGetters} from 'vuex';
import instance from "@/api/instance";
import ServerErrorPopup from "@/components/popups/ServerErrorPopup";
import StepProgressBar from "@/components/profile/StepProgressBar";
import CountrySelect from "@/components/inputs/CountrySelect";
import scrollToElement from "@/mixins/scrollToElement";
import {required, email, minLength, maxLength} from "vuelidate/lib/validators";
import {mapActions} from "vuex";

export default {
  name: "Verification",
  data() {
    return {
      loadFlag: false,
      token: '',
      nextKYCstep: false,
      KYCStatus: null,
      focus: false,
      doc1: null,
      doc2: null,
      companyStepStatus: {},
      companyStepCurrent: 1,
      companyStepTotal: 3,
      companyStepNames: ['Company Information', 'Registration documents', 'UBO Declaration'],
      searchSelect: '',
      countriesList: [],
      nationalitiesList: [],
      tabs: JSON.parse(localStorage.getItem('tabs')),
      error: {
        errorMessage: '',
      },
      menuDateFirst: false,
      activePickerFirst: null,
      beneficialLengthError: false,
      formFilesData: new FormData(),
      companyDataSecondStep: new FormData(),
      fakeFirstFileData: '',
      fakeSecondFileData: '',
      fakeThirdFileData: '',
      fakeFourthFileData: '',
      companyDataFirstStep: {
        companyName: null,
        companyNumber: null,
        email: null,
        headquartersAddress: {
          addressLine1: null,
          addressLine2: null, // not required
          city: null,
          country: null, // might be countryId with code 'AD'
          postalCode: null,
          region: null, // not required
        },
        legalRepresentativeBirthday: null,
        legalRepresentativeCountryOfResidence: null, // code 'AD'
        legalRepresentativeEmail: null, // not for user
        legalRepresentativeFirstName: null,
        legalRepresentativeLastName: null,
        legalRepresentativeNationality: null,
        legalRepresentativeAddress: {
          addressLine1: null,
          addressLine2: null, // not required
          city: null,
          country: null, // might be countryId with code 'AD'
          postalCode: null,
          region: null, // not required
        },
      },
      companyDataThirdStep: {
        declarationId: null, // not for user
        ubos: [
          {
            menuDateUbo: false, // not for user, not for backend. Delete this until submit
            activePickerUbo: 'YEAR', // not for user, not for backend. Delete this until submit
            uboId: null, // not for user, submit when get request return not null
            firstName: null,
            lastName: null,
            nationality: null,
            birthday: null,
            address: {
              addressLine1: null,
              addressLine2: null, // not required
              city: null,
              country: null,
              postalCode: null,
              region: null, // not required
            },
            birthplaceCity: null,
            birthplaceCountry: null,
          },
        ],
      },
    }
  },
  validations() {
    if (this.companyStepCurrent === 1) {
      return {
        companyDataFirstStep: {
          companyName: {
            required
          },
          companyNumber: {
            required
          },
          email: {
            required,
            email
          },
          headquartersAddress: {
            addressLine1: {
              required
            },
            city: {
              required
            },
            country: { // might be countryId with code 'AD'
              required
            },
            postalCode: {
              required
            },
          },
          legalRepresentativeBirthday: {
            required
          },
          legalRepresentativeCountryOfResidence: {
            required
          },
          // legalRepresentativeEmail: {
          //   required
          // },
          legalRepresentativeFirstName: {
            required
          },
          legalRepresentativeLastName: {
            required
          },
          legalRepresentativeNationality: {
            required
          },
          legalRepresentativeAddress: {
            addressLine1: {
              required
            },
            city: {
              required
            },
            country: { // might be countryId with code 'AD'
              required
            },
            postalCode: {
              required
            },
          },
        },
      }
    } else if (this.companyStepCurrent === 2) {
      return {
        fakeFirstFileData: {
          required: !this.secondStepIdentityProof ? required : ''
        },
        fakeSecondFileData: {
          required: !this.secondStepArticlesOfAssociation ? required : ''
        },
        fakeThirdFileData: {
          required: !this.secondStepIRegistrationProof ? required : ''
        },
        fakeFourthFileData: {
          required: !this.secondStepIShareholderDeclaration ? required : ''
        },
      }
    } else if (this.companyStepCurrent === 3) {
      return {
        companyDataThirdStep: {
          ubos: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(4),
            $each: {
              menuDateUbo: {
                // not required
              },
              activePickerUbo: {},
              firstName: {
                required
              },
              lastName: {
                required
              },
              nationality: {
                required
              },
              birthday: {
                required
              },
              address: {
                addressLine1: {
                  required
                },
                addressLine2: {
                  // not required
                },
                city: {
                  required
                },
                country: {
                  required
                },
                postalCode: {
                  required
                },
                region: {
                  // not required
                },
              },
              birthplaceCountry: {
                required
              },
              birthplaceCity: {
                required
              },
            }
          }
        }
      }
    }
  },
  mixins: [scrollToElement],
  methods: {
    ...mapActions({
      setTabs: 'userTabs/setTabs',
    }),
    createImage(name, file) {
      const reader = new FileReader();
      const vm = this;

      reader.onload = (e) => {
        if (name === 'frontSide') {
          vm.doc1 = e.target.result
        } else if (name === 'backSide') {
          vm.doc2 = e.target.result
        }
      };
      reader.readAsDataURL(file);
    },
    setFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      let name = e.target.name
      if (name === 'frontSide') {
        this.doc1 = e.target.value
      } else if (name === 'backSide') {
        this.doc2 = e.target.value
      }
      if (!files.length) return;
      this.formFilesData.set(name, files[0])
      this.createImage(name, files[0])
      // this.formFilesData.set(name, this.$refs.myFiles.$refs.input.files[0])
    },
    setCompanyFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      let name = e.target.name
      let fileName = e.target.files
      if (name === 'identifyDoc') {
        if (files.length) {
          this.fakeFirstFileData = fileName[0].name
        }
      } else if (name === 'statuteDoc') {
        if (files.length) {
          this.fakeSecondFileData = fileName[0].name
        }
      } else if (name === 'registrationDoc') {
        if (files.length) {
          this.fakeThirdFileData = fileName[0].name
        }
      } else if (name === 'shareholderDoc') {
        if (files.length) {
          this.fakeFourthFileData = fileName[0].name
        }
      }
      if (!files.length) return;
      this.companyDataSecondStep.set(name, files[0])
    },
    statusRequest() {
      this.$load(async () => {
        this.loadFlag = false
        this.KYCStatus = (await this.$api.kyc.getAuthStatus()).data.body
        this.loadFlag = true
        if (this.KYCStatus === 'NOT_STARTED') {
          this.nextKYCstep = true
        } else {
          setTimeout(() => {
            this.loadKYC()
          }, 0)
        }
      })
    },
    launchWebSdk(accessToken, applicantEmail, applicantPhone) {
      let snsWebSdkInstance = snsWebSdk.init(
          accessToken,
          () => this.getNewAccessToken()
      )
          .withConf({
            lang: 'en',
            email: applicantEmail,
            phone: applicantPhone,
            i18n: {"document": {"subTitles": {"IDENTITY": "Upload a document that proves your identity"}}},
            onMessage: (type, payload) => {
              // console.log('WebSDK onMessage', type, payload)
            },
            uiConf: {
              customCssStr: ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}"
            },
            onError: (error) => {
              // console.error('WebSDK onError', error)
            },
          })
          .withOptions({addViewportTag: false, adaptIframeHeight: true})
          .on('stepCompleted', (payload) => {
            // console.log('stepCompleted', payload)
          })
          .on('onError', (error) => {
            // console.log('onError', payload)
          })
          .onMessage((type, payload) => {
            // console.log('onMessage', type, payload)
          })
          .build();
      snsWebSdkInstance.launch('#sumsub-websdk-container')
    },
    launchWebSdkDev(accessToken, applicantEmail, applicantPhone) {
      let snsWebSdkInstance = snsWebSdk.init(accessToken, () => this.getNewAccessToken())
          .withConf({
            lang: 'en',
            email: applicantEmail,
            phone: applicantPhone,
            i18n: {"document": {"subTitles": {"IDENTITY": "Upload a document that proves your identity"}}},
            onMessage: (type, payload) => {
              // console.log('WebSDK onMessage', type, payload)
            },
            uiConf: {
              customCssStr: ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}"
            },
            onError: (error) => {
              console.error('WebSDK onError', error)
            },
          })
          .withOptions({addViewportTag: false, adaptIframeHeight: true})
          .on('stepCompleted', (payload) => {
            // console.log('stepCompleted', payload)
          })
          .on('onError', (error) => {
            // console.log('onError', payload)
          })
          .onMessage((type, payload) => {
            // console.log()
          })
          .onTestEnv()
          .build();
      snsWebSdkInstance.launch('#sumsub-websdk-container')
    },
    getNewAccessToken() {
      this.loadFlag = false
      let res = instance.get(`${process.env.VUE_APP_API}api/v2/kyc/token`)
      let token
      res.then(res => {
        token = res.data.body.value
      })
      this.loadFlag = true
      return Promise.resolve(token)// get a new token from your backend
    },
    loadKYC() {
      this.$load(async () => {
        const uuid = JSON.parse(localStorage.getItem('user')).uuid
        const res = (await this.$api.kyc.getKYCAidFormUrl(uuid))
        window.location = res.data.body.value
      })

    },
    uploadDocs() {
      if (this.compareItems()) {
        this.$load(async () => {
          await this.$api.kyc.uploadMangopayDocs()
          await this.$router.push({path: '/profile'})
        }, error => {
          this.error.errorMessage = error.data.errors[0].message
          this.$refs.serverErrorPopup.openPopups()
        })
      }
    },
    compareItems() {
      let doc1 = this.doc1
      let doc2 = this.doc2
      if (doc1 === null || doc1 === '' || doc2 === null || doc2 === '') {
        return false
      } else {
        return doc1 !== doc2
      }
    },
    selectedField(id) {
      if (this.companyStepCurrent === 1) {
        if (id === 'kyc__company_address') {
          this.$refs.KYCCompanyLegalDetailsCountrySelect.closeList()
          this.$refs.KYCCompanyAddressRepresentativeCountrySelect.closeList()
          this.$refs.KYCCompanyLegalDetailsNationality.closeList()
          return
        }
        if (id === 'kyc__legal_details') {
          this.$refs.KYCCompanyAddressCountrySelect.closeList()
          this.$refs.KYCCompanyAddressRepresentativeCountrySelect.closeList()
          this.$refs.KYCCompanyLegalDetailsNationality.closeList()
          return
        }
        if (id === 'kyc__representative_address') {
          this.$refs.KYCCompanyLegalDetailsCountrySelect.closeList()
          this.$refs.KYCCompanyAddressCountrySelect.closeList()
          this.$refs.KYCCompanyLegalDetailsNationality.closeList()
          return
        }
        if (id === 'kyc__legal_nationality') {
          this.$refs.KYCCompanyLegalDetailsCountrySelect.closeList()
          this.$refs.KYCCompanyAddressCountrySelect.closeList()
          this.$refs.KYCCompanyAddressRepresentativeCountrySelect.closeList()
          return
        }
      }
      if (this.companyStepCurrent === 3) {
        let array = Object.values(this.$refs)
        let existingArray = array.filter(i => i)
        let arrayOfCountry = existingArray.filter(i => i[0]).filter(i => 'getCountryByCode' in i[0])
        let otherArray = arrayOfCountry.filter(i => !i[0].identifier.includes(id))
        otherArray.forEach((el) => el[0].closeList())
      }
    },
    countryClickOutside(event) {
      if (this.companyStepCurrent === 1 && this.loadFlag) {
        if (!event.target.closest('.general_select')) {
          this.$refs.KYCCompanyAddressCountrySelect.closeList()
          this.$refs.KYCCompanyLegalDetailsCountrySelect.closeList()
          this.$refs.KYCCompanyAddressRepresentativeCountrySelect.closeList()
          this.$refs.KYCCompanyLegalDetailsNationality.closeList()
        }
      } else if (this.companyStepCurrent === 3 && this.loadFlag) {
        if (!event.target.closest('.general_select')) {
          let array = Object.values(this.$refs)
          let existingArray = array.filter(i => i)
          let arrayOfCountry = existingArray.filter(i => i[0]).filter(i => 'getCountryByCode' in i[0])
          arrayOfCountry.forEach((el) => el[0].closeList())
        }
      }
    },
    countryListRequest() {
      this.$load(async () => {
        this.loadFlag = false
        let res = await this.$api.countriesLists.getBankCountries()
        this.countriesList = res.data.body.elements
        this.loadFlag = true
      })
    },
    nationalitiesListRequest() {
      this.$load(async () => {
        this.loadFlag = false
        let res = await this.$api.countriesLists.getNationalities()
        this.nationalitiesList = res.data.body.elements
        this.loadFlag = true
      })
    },
    searchCheck(data) {
      this.searchSelect = data
    },
    setCompanyNationality(data) {
      if (this.companyStepCurrent === 1) {
        this.companyDataFirstStep.legalRepresentativeNationality = data.code
      }
    },
    setCompanyAddressCountry(data) {
      if (this.companyStepCurrent === 1) {
        this.companyDataFirstStep.headquartersAddress.country = data.code
      }
    },
    setCompanyResidenceCountry(data) {
      if (this.companyStepCurrent === 1) {
        this.companyDataFirstStep.legalRepresentativeCountryOfResidence = data.code
      }
    },
    setCompanyLegalAddressCountry(data) {
      if (this.companyStepCurrent === 1) {
        this.companyDataFirstStep.legalRepresentativeAddress.country = data.code
      }
    },
    setCompanyBeneficialCountry(data, identifier) {
      if (this.companyStepCurrent === 3) {
        let arrayData = this.companyDataThirdStep.ubos
        const str = '-index:'
        let indexOfStr = identifier.indexOf(str)
        let getIndexItem = identifier.slice(indexOfStr + str.length)
        // find in array needed object
        let result = arrayData[getIndexItem]

        if (identifier.includes('kyc__beneficial-nationality')) {
          result.nationality = data.code
          return
        }
        if (identifier.includes('kyc__beneficial-address_country')) {
          result.address.country = data.code
          return
        }
        if (identifier.includes('kyc__beneficial-birthplace_country')) {
          result.birthplaceCountry = data.code
        }
      }
    },
    companyFirstStepRequest() {
      this.$load(async () => {
        this.loadFlag = false
        let res = (await this.$api.kyc.getFirstRequest()).data.body
        let formData = this.companyDataFirstStep
        formData.companyName = res.companyName
        formData.companyNumber = res.companyNumber
        formData.email = res.email
        formData.legalRepresentativeBirthday = res.legalRepresentativeBirthday
        formData.legalRepresentativeCountryOfResidence = res.legalRepresentativeCountryOfResidence
        formData.legalRepresentativeFirstName = res.legalRepresentativeFirstName
        formData.legalRepresentativeLastName = res.legalRepresentativeLastName
        formData.legalRepresentativeNationality = res.legalRepresentativeNationality
        formData.legalRepresentativeEmail = res.legalRepresentativeEmail
        // formData.headquartersAddress = res.headquartersAddress // object
        // formData.legalRepresentativeAddress = res.legalRepresentativeAddress // object
        this.loadFlag = true
      }, error => {
        this.error.errorMessage = error.data.errors[0].message
        this.$refs.serverErrorPopup.openPopups()
      })
    },
    submitCompanyFirstStep() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$load(async () => {
        this.loadFlag = false
        this.companyDataFirstStep.legalRepresentativeEmail = this.companyDataFirstStep.email
        await this.$api.kyc.uploadFirstStep(this.companyDataFirstStep)
        if (this.tabs.kycTab.kycFlow === 'MANGOPAY_LEGAL_FIRST') {
          this.companyStepCurrent = 2
        } else if (this.tabs.kycTab.kycFlow === 'MANGOPAY_LEGAL_SECOND') {
          this.companyStepStatusRequest()
        }
        this.loadFlag = true
      }, error => {
        this.loadFlag = true
        this.error.errorMessage = error.data.errors[0].message
        this.$refs.serverErrorPopup.openPopups()
      })
    },
    companyStepStatusRequest() {
      this.$load(async () => {
        this.loadFlag = false
        let res = await this.$api.kyc.getStatusOfSteps()
        this.companyStepStatus = res.data.body
        if (res.status) {
          if (this.companyStepStatus.hasOwnProperty('mangoUserExists')) {
            if (!this.companyStepStatus.mangoUserExists) {
              this.companyStepCurrent = 1
              this.companyFirstStepRequest()
              return
            }
            if (!this.companyStepStatus.kycValid) {
              this.companyStepCurrent = 2
              return
            }
            if (!this.companyStepStatus.uboValid) {
              this.companyStepCurrent = 3
              this.companyThirdStepRequest()
              return
            }

            let tab = await this.setTabs()
            if (tab) {
              if (this.tabs.kycTab.kycFlow === 'MANGOPAY_LEGAL_SECOND'
                  && (this.companyStepStatus.mangoUserExists
                      && this.companyStepStatus.kycValid
                      && this.companyStepStatus.uboValid)) {
                return
              }
            }
            // await location.reload()
            await this.$router.push({path: '/profile/kyc', query: {timestamp: Date.now().toString()}})
          }
        }
        this.loadFlag = true
      }, error => {
        this.error.errorMessage = error.data.errors[0].message
        this.$refs.serverErrorPopup.openPopups()
      })
    },
    submitCompanySecondStep() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      } else {
        this.$load(async () => {
          this.loadFlag = false
          let res = await this.$api.kyc.uploadSecondStep(this.companyDataSecondStep)
          if (res.status) {
            if (this.tabs.kycTab.kycFlow === 'MANGOPAY_LEGAL_FIRST') {
              this.companyStepCurrent = 3
            } else if (this.tabs.kycTab.kycFlow === 'MANGOPAY_LEGAL_SECOND') {
              await this.setTabs()
              this.companyStepStatusRequest()
            }
          }
          this.loadFlag = true
        }, error => {
          this.loadFlag = true
          this.error.errorMessage = error.data.errors[0].message
          this.$refs.serverErrorPopup.openPopups()
        })
      }
    },
    addBeneficial() {
      let beneficialArr = this.companyDataThirdStep.ubos
      let obj = {
        menuDateUbo: false, // delete this until submit
        activePickerUbo: 'YEAR', // delete this until submit
        uboId: null,
        firstName: null,
        lastName: null,
        nationality: null,
        birthday: '',
        address: {
          addressLine1: null,
          addressLine2: null, // not required
          city: null,
          country: null,
          postalCode: null,
          region: null, // not required
        },
        birthplaceCity: null,
        birthplaceCountry: null,
      }
      if (beneficialArr.length > 0 && beneficialArr.length <= 3) {
        beneficialArr.push(obj)
        this.beneficialLengthError = false
      } else {
        if (!this.beneficialLengthError) {
          this.beneficialLengthError = true
          setTimeout(() => {
            this.beneficialLengthError = false
          }, 2500)
        }
      }
    },
    deleteBeneficial(index) {
      let beneficialArr = this.companyDataThirdStep.ubos
      if (beneficialArr.length > 1) {
        beneficialArr.splice(index, 1)
      }
    },
    submitCompanyThirdStep() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      } else {
        this.$load(async () => {
          this.loadFlag = false
          let array = this.companyDataThirdStep.ubos
          array.forEach((el) => delete el.menuDateUbo && delete el.activePickerUbo)
          let res = await this.$api.kyc.uploadThirdStep(this.companyDataThirdStep)
          if (res.status) {
            await this.setTabs()

            if (this.tabs.kycTab.kycFlow === 'MANGOPAY_LEGAL_FIRST') {
              // await location.reload()
            }
            if (this.tabs.kycTab.kycFlow === 'MANGOPAY_LEGAL_SECOND') {
              this.companyStepStatusRequest()
            }
            if (this.tabs.kycTab.kycFlow === 'IN_PROGRESS') {
              // await location.reload()
              this.companyStepCurrent = 0
            }
          }
          this.loadFlag = true
        }, error => {
          this.loadFlag = true
          this.error.errorMessage = error.data.errors[0].message
          this.$refs.serverErrorPopup.openPopups()
        })
      }
    },
    companyThirdStepRequest() {
      this.$load(async () => {
        this.loadFlag = false
        let res = (await this.$api.kyc.getThirdRequest()).data.body
        this.companyDataThirdStep.declarationId = res.declarationId
        if (res.ubos !== null) {
          if (res.ubos.length > 1) {
            console.log('')
          } else {
            res.ubos.push(this.companyDataThirdStep.ubos)
          }
        }
        this.loadFlag = true
      }, error => {
        this.error.errorMessage = error.data.errors[0].message
        this.$refs.serverErrorPopup.openPopups()
      })
    },
    saveDate(date) {
      if (this.companyStepCurrent === 1) {
        this.$refs.KYCCompanyFirstStepMenu.save(date)
        this.companyDataFirstStep.legalRepresentativeBirthday = date
      }
    },
    saveDatePickerThirdStep(index, data) {
      if (this.companyStepCurrent === 3) {
        let refName = `KYCCompanyThirdStepMenu-index:${index}`
        let array = Object.values(this.$refs)
        let existingArray = array.filter(i => i)
        let dateMenu = existingArray.filter(el => el[0]).find(i => i[0].$vnode.data.ref.includes(refName))

        dateMenu[0].save(data)
        this.companyDataThirdStep.ubos[index].birthday = data
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      userTabs: 'userTabs/getTabs',
    }),
    isDocsValid() {
      let doc1 = this.doc1
      let doc2 = this.doc2
      return this.focus ? doc1 === null || doc1 === '' || doc2 === null || doc2 === '' ? doc1 && doc2 : true : true
    },
    identityCheck() {
      let doc1 = this.doc1
      let doc2 = this.doc2
      return this.focus ? doc1 !== null && doc2 !== null ? doc1 !== doc2 : true : true
    },
    searchedCountries() {
      return this.countriesList.filter(option => option.name.toLowerCase().includes(this.searchSelect.toLowerCase()))
    },
    searchedNationalities() {
      return this.nationalitiesList.filter(option => option.name.toLowerCase().includes(this.searchSelect.toLowerCase()))
    },
    disableSecondSubmit() {
      return this.$v && this.companyStepCurrent === 2 ? this.$v.$invalid : true
    },
    secondStepIdentityProof() {
      return this.companyStepStatus.hasOwnProperty('kycDocsValid') ? this.companyStepStatus.kycDocsValid.IDENTITY_PROOF : false
    },
    secondStepArticlesOfAssociation() {
      return this.companyStepStatus.hasOwnProperty('kycDocsValid') ? this.companyStepStatus.kycDocsValid.ARTICLES_OF_ASSOCIATION : false
    },
    secondStepIRegistrationProof() {
      return this.companyStepStatus.hasOwnProperty('kycDocsValid') ? this.companyStepStatus.kycDocsValid.REGISTRATION_PROOF : false
    },
    secondStepIShareholderDeclaration() {
      return this.companyStepStatus.hasOwnProperty('kycDocsValid') ? this.companyStepStatus.kycDocsValid.SHAREHOLDER_DECLARATION : false
    },
    inProgressFlag() {
      return this.userTabs.kycTab.kycFlow && this.userTabs.kycTab.kycFlow === 'IN_PROGRESS'
    },
  },
  watch: {
    menuDateFirst(val) {
      val && setTimeout(() => (this.activePickerFirst = 'YEAR'))
    },
  },
  async mounted() {
    document.title = 'Verification'
    this.scrollToId('my-content')
    await this.setTabs()

    let tab = this.userTabs.kycTab.kycFlow
    if (tab === 'SUMSUB_NATURAL' || tab === 'MANGOPAY_UPLOAD_NATURAL') {
      this.statusRequest()
    }
    if (tab === 'MANGOPAY_LEGAL_FIRST' || tab === 'MANGOPAY_LEGAL_SECOND' || tab === 'IN_PROGRESS') {
      this.countryListRequest()
      this.nationalitiesListRequest()
      document.addEventListener('click', this.countryClickOutside)
    }
    if (tab === 'MANGOPAY_LEGAL_FIRST') {
      this.companyFirstStepRequest()
    }
    if (tab === 'MANGOPAY_LEGAL_SECOND') {
      this.companyStepStatusRequest()
    }
    if (tab === 'IN_PROGRESS') {
      this.companyStepCurrent = 0
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.countryClickOutside)
  },
  components: {
    ServerErrorPopup,
    StepProgressBar,
    CountrySelect,
  }
}
</script>

<style>

/* animation  */

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* end animation  */

.kyc-company__wrap .error-message {
  position: absolute;
  color: #EF3939;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 18px;
  left: 10px;
  bottom: -19px;
}

.kyc-status-wrapper {
  width: 100%;
}

.kyc-body-wrapper {
  width: 100%;
}

.kyc-body-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 320px;
  margin: 0 auto;
}

.kyc-body-container label {
  width: 100%;
}

.add-document {
  width: 0;
  height: 0;
  opacity: 0;
}

.fake-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  width: 100%;
  height: 200px;
  background: #FFF2F9;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 13px;
  color: #131415;
}

.fake-input svg {
  margin-bottom: 20px;
}

.kyc-mangopay-docs-images {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 38px;
  background: #F9F9F9;
  margin-top: 20px;
}

.docs-images-item {
  width: calc(33.333% - 20px);
  border-radius: 15px;
  height: 160px;
  position: relative;
  background: #FFFFFF;
}

.docs-images-item__first {
  width: 174px;
  height: 113px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.docs-images-item__second {
  width: 181px;
  height: 97px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.docs-images-item__third {
  width: 95px;
  height: 60px;
  position: absolute;
  top: 30px;
  left: 40px;
}

.docs-images-item__sign {
  width: 23px;
  height: 21px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.kyc-mangopay-docs-upload {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.docs-upload-wrap {
  width: calc(50% - 10px);
  background: rgba(255, 0, 144, 0.03);
  border: 1px solid rgba(255, 0, 144, 0.2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

label.docs-upload-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px 0;
  cursor: pointer;
}

.fake-input__upload {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #131415;
}

.fake-input__upload span {
  color: #FF0090;
}

.docs-upload-item svg {
  margin-right: 36px;
}

/*.profile.kyc input:read-only {*/
/*  background: unset;*/
/*  border: unset;*/
/*  border-radius: unset;*/
/*  padding: unset;*/
/*  cursor: unset;*/
/*}*/

.kyc-mangopay-docs-button {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.btn.disabled {
  background: #D0D0D0;
}

.btn.disabled:hover {
  color: #FFF;
  border-color: unset;
}

.docs-upload__error {
  width: 100%;
  text-align: center;
  color: red;
  margin-top: 10px;
}

.kyc-preview-docs {
  padding: 40px 15px 15px 15px;
  position: relative;
  max-width: 600px;
  height: auto;
  display: block;
  margin-bottom: 0;
}

.kyc-preview-docs__reload {
  position: absolute;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
  cursor: pointer;
}

.kyc-preview-docs__reload svg {
  transition: .5s ease;
}

.kyc-preview-docs__reload:hover svg {
  opacity: 0.6;
}

.fake-input__upload-text {
  margin-bottom: 0;
}

/* ===== KYC COMPANY ===== */

.profile-subtitle-wrap.kyc-subtitle {
  padding-bottom: 8px;
  margin-bottom: 20px;
}

.profile-subtitle-wrap.kyc-subtitle .profile-subtitle {
  max-width: calc(100% - 50px);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 4.8px;
}

.profile-subtitle-wrap.subtitle-beneficial-wrap {
  margin-bottom: 45px;
  position: relative;
}

.asterisk {
  color: #EF3939;
}

.kyc-company__disclaimer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

p.kyc-company__reject-reason {
  margin-bottom: 0;
  color: #EF3939;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
}

.kyc-company__wrap {
  width: 100%;
}

.kyc-company__section {
  margin-bottom: 40px;
}

.kyc-company__row-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.kyc-company__row-wrap:last-of-type {
  margin-bottom: 0;
}

.kyc-company__field-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #767575;
  margin-right: 10px;
}

.profile-input-wrapper.kyc-company__field-wrap {
  width: calc(100% - 175px);
  margin-bottom: 0;
}

.profile-input-wrapper.kyc-company__field-wrap .v-text-field {
  padding: 0;
  margin: 0;
}

.profile-input-wrapper.kyc-company__field-wrap input {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #2C2C2C;
}

.kyc-company__row-date-of-birth {
  width: 50%;
}

.kyc-company__field-date-of-birth {
  width: calc(100% - 175px);
  cursor: pointer;
  position: relative;
}

.kyc-company__field-date-of-birth .v-input.v-text-field input {
  font-weight: 400;
  font-size: 20px;
  max-height: unset;
  height: 51px;
  cursor: pointer;
}

.kyc-company__field-date-of-birth .v-input .v-label {
  height: 48px;
  line-height: 48px;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: unset;
  margin-bottom: 0;
  padding-left: 30px;
}

.kyc-company__field-date-of-birth .v-text-field .v-label--active {
  display: none;
}

.kyc-company__column-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% - 175px);
  margin-left: auto;
}

.kyc-company__column-item {
  width: calc(50% - 22px);
  margin-bottom: 20px;
  position: relative;
}

.kyc-company__column-item:nth-child(n + 3) {
  margin-bottom: 0;
}

.kyc-company__column-item .kyc-company__field-title {
  margin-right: 0;
  margin-bottom: 8px;
}

.kyc-company__column-item .kyc-company__field-wrap {
  width: 100%;
}

.btn.kyc-company__submit-btn {
  display: block;
  padding: 14px 20px;
  margin: 0 auto 125px auto;
  max-width: 235px;
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.kyc-company__reg-docs-wrap .profile-subtitle-wrap {
  margin-bottom: 30px;
}

.kyc-company__reg-docs-wrap .kyc-company__section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.kyc-company__reg-docs-wrap .kyc-company__row-wrap {
  max-width: 700px;
  width: 100%;
}

.kyc-company__reg-docs-wrap .kyc-company__field-title {
  display: flex;
  align-items: center;
}

.kyc-company__file-field {
  height: 50px;
  width: calc(100% - 200px);
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #C6C6C6;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 24px 0 52px;
  cursor: pointer;
}

.kyc-company__file-field .file-field__type {
  position: absolute;
  left: 0;
  bottom: -16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #767575;
}

.kyc-company__file_input {
  display: none;
}

.kyc-company__field-title-text {
  margin-right: 8px;
}

.kyc-company__file-field-text {
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #2C2C2C;
  transition: .5s ease;
}

.kyc-company__file-field .file-field__upload-item {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.file-field__upload-item svg {
  transition: .5s ease;
}

.kyc-company__file-field:not(.file-field__valid):hover .kyc-company__file-field-text,
.kyc-company__file-field:not(.file-field__valid):hover .file-field__upload-item svg {
  opacity: 0.5;
}

.kyc-company__file-field .file-field__valid-icon {
  position: absolute;
  right: -37px;
  top: 50%;
  transform: translateY(-50%);
  cursor: default;
}

.kyc-company__file-field .file-field__valid-icon svg {
  transition: .5s ease;
}

.kyc-company__file-field.file-field__valid {
  border: 1px solid #F9F9F9;
  border-radius: 5px;
  background: #F9F9F9;
  cursor: not-allowed;
}

.file-field__valid .file-field__upload-item,
.file-field__valid .kyc-company__file-field-text {
  display: none;
}

.file-field__valid-text {
  display: none;
  color: #5A5A5A;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
}

.file-field__valid .file-field__valid-text {
  display: block;
}

.kyc-company__reg-docs-wrap .btn.kyc-company__submit-btn {
  margin-bottom: 25px;
}

.profile-subtitle.add-beneficial-btn {
  color: #FF0090;
  transition: .5s ease;
}

.profile-subtitle.add-beneficial-btn:hover {
  opacity: 0.6;
}

.kyc-company__beneficial-container {
  margin-bottom: 60px;
}

.kyc-company__beneficial-container:last-of-type {
  margin-bottom: 0;
}

.kyc-company__field-wrap input:disabled:read-only {
  padding: 0 30px;
  border: 1px solid #F9F9F9;
  border-radius: 5px;
  color: #5A5A5A;
  background: #F9F9F9;
  cursor: not-allowed;
}

.kyc-subtitle__btn-wrap {
  display: flex;
  justify-content: space-between;
}

.kyc-company__delete-beneficial {
  margin-left: 10px;
}

.kyc-company__delete-beneficial svg {
  transition: .5s ease;
}

.kyc-company__delete-beneficial:hover svg {
  transform: scale(1.2);
}

.error-message.beneficial-error {
  bottom: -29px;
}

.error-message.beneficial-error svg {
  margin-right: 10px;
}

.error-message.beneficial-error svg path {
  stroke: #EF3939;
}

.kyc__in_progress .step-progress {
  margin-bottom: 70px;
}


.kyc-company__in_progress-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.kyc-company__in_progress {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 405px;
  padding: 45px 66px;
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 20px;
}

.kyc-company__in_progress-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 92px;
  height: 92px;
  border-radius: 50%;
  background: rgba(255, 0, 144, 0.06);
  margin-bottom: 30px;
}

.kyc-company__in_progress-text-block {
  /*display: flex;*/
  /*justify-content: center;*/
}

p.kyc-company__in_progress-title {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #131415;
}

p.kyc-company__in_progress-text {
  text-align: center;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #5A5A5A;
  margin-bottom: 0;
}

.kyc-company__row-wrap.shareholders .kyc-company__field-title {
  max-width: 185px;
}

/* ===== end KYC COMPANY ===== */

@media only screen and (max-width: 992px) {
  label.docs-upload-item {
    padding: 40px 20px;
  }

  .fake-input__upload {
    flex-wrap: wrap;
  }

  .fake-input__upload svg {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .fake-input__upload-text {
    width: 100%;
    text-align: center;
  }

  .profile-input-wrapper.kyc-company__field-wrap {
    width: 100%;
  }

  .kyc-company__row-date-of-birth {
    width: 100%;
  }

  .kyc-company__field-date-of-birth {
    width: 100%;
  }

  .kyc-company__row-wrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .kyc-company__row-wrap.shareholders .kyc-company__field-title {
    max-width: unset;
  }

  .kyc-company__file-field {
    width: 100%;
    padding: 0 52px;
  }

  .kyc-company__file-field .file-field__valid-icon {
    right: 15px;
  }

  .kyc-company__field-title {
    margin-right: 0;
    margin-bottom: 12px;
  }

  .kyc-company__column-item .kyc-company__field-title {
    margin-bottom: 12px;
  }

  .kyc-company__field-wrap {
    width: 100%;
  }

  .kyc-company__column-wrap {
    width: 100%;
    flex-direction: column;
    margin-left: 0;
  }

  .kyc-company__column-item {
    width: 100%;
  }

  .kyc-company__column-item:nth-child(n + 3) {
    margin-bottom: 20px;
  }

  .kyc-company__column-item:last-child {
    margin-bottom: 0;
  }

  .btn.kyc-company__submit-btn {
    margin-bottom: 55px;
  }
}

@media only screen and (max-width: 768px) {
  .error-message.beneficial-error {
    bottom: -40px;
  }
}

@media only screen and (max-width: 650px) {
  .docs-upload-wrap {
    width: 100%;
    margin-bottom: 20px;
  }

  .kyc-mangopay-docs-images {
    padding: 15px;
  }

  .docs-images-item {
    width: calc(33.333% - 10px);
    height: 65px;
  }

  .docs-images-item__first {
    width: 60%;
    height: 60%;
  }

  .docs-images-item__sign {
    width: 8px;
    height: 8px;
    bottom: 8px;
    right: 8px;
  }

  .docs-images-item__second {
    width: 63px;
    height: 32px;
  }

  .docs-images-item__third {
    width: 38px;
    height: 24px;
    top: 12px;
    left: 14px;
  }

  label.docs-upload-item {
    padding: 20px 0;
  }

  .kyc-mangopay-docs-button {
    margin-top: 20px;
  }

  .kyc-company__in_progress-container {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 400px) {
  .btn.kyc-company__submit-btn {
    max-width: 100%;
  }
}

</style>